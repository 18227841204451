import ActionTypes from 'constants/ActionTypes';

export const closeAll = () => ({
    type: ActionTypes.UI_ALL_CLOSE,
});

export const showHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_SHOW,
});

export const closeHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_CLOSE,
});

export const showListPanel = () => ({
    type: ActionTypes.UI_PANELS_LIST_SHOW,
});

export const closeListPanel = () => ({
    type: ActionTypes.UI_PANELS_LIST_CLOSE,
});

export const closeAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_CLOSE,
});

export const showAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_SHOW,
});

export const setListPanelFilter = filter => ({
    type: ActionTypes.UI_PANELS_LIST_FILTER_SET,
    error: false,
    payload: filter,
});

export const resetListPanelFilter = () => ({
    type: ActionTypes.UI_PANELS_LIST_FILTER_RESET,
});

export const showAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW,
});

export const closeAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE,
});

export const showNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW,
});

export const closeNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE,
});

export const showAddToListMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ADD_TO_LIST_SHOW,
});

export const closeAddToListMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ADD_TO_LIST_CLOSE,
});

export const showPricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_SHOW,
});

export const closePricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_CLOSE,
});

export const showExportMessage = () => ({
    type: ActionTypes.UI_MESSAGES_EXPORT_SHOW,
});

export const closeExportMessage = () => ({
    type: ActionTypes.UI_MESSAGES_EXPORT_CLOSE,
});

export const showGaugeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_GAUGE_INFO_SHOW,
});

export const closeGaugeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_GAUGE_INFO_CLOSE,
});

export const showRefreshConfirmMessage = () => ({
    type: ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_SHOW,
});

export const closeRefreshConfirmMessage = () => ({
    type: ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_CLOSE,
});

export const proceedRefreshConfirmMessage = payload => ({
    type: ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_PROCEED,
    error: false,
    payload,
});

export const showNeedToSelectKeywordMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW,
});

export const closeNeedToSelectKeywordMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE,
});

export const showUpgradeForMoreResultsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_SHOW,
});

export const closeUpgradeForMoreResultsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_CLOSE,
});

export const showFailureMessage = ({ details, header }) => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_SHOW,
    error: false,
    payload: { details, header },
});

export const closeFailureMessage = () => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_CLOSE,
});

export const showDeleteConfirmationMessage = payload => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW,
    error: false,
    payload,
});

export const closeDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE,
});

export const confirmDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM,
});

export const showOverlay = () => ({
    type: ActionTypes.UI_OVERLAY_SHOW,
});

export const closeOverlay = () => ({
    type: ActionTypes.UI_OVERLAY_CLOSE,
});

export const selectKeywords = payload => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT,
    error: false,
    payload,
});

export const unselectKeywords = payload => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT,
    error: false,
    payload,
});

export const requestedSelectAllKeywords = () => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_SELECT_ALL_REQUESTED,
});

export const unselectAllKeywords = () => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_UNSELECT_ALL,
});

export const setSelectedKeywords = payload => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_KEYWORD_IDS_SET,
    error: false,
    payload,
});

export const setNavigatedInternally = () => ({
    type: ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET,
});

export const setNewVersionNotificationShown = () => ({
    type: ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET,
});

export const resetSortingSettings = () => ({
    type: ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_RESET,
});

export const setSortingSettings = (column, type) => ({
    type: ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_SET,
    error: false,
    payload: {
        column,
        type,
    },
});

export const toggleSerpExpand = () => ({
    type: ActionTypes.UI_DASHBOARD_SERP_EXPAND_TOGGLE,
});

export const resetFilter = () => ({
    type: ActionTypes.UI_FILTER_RESET,
});

export const setFilterActive = payload => ({
    type: ActionTypes.UI_FILTER_ACTIVE_SET,
    error: false,
    payload,
});

export const setFilterSettings = payload => ({
    type: ActionTypes.UI_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const toggleFilterVisibility = () => ({
    type: ActionTypes.UI_FILTER_VISIBILITY_TOGGLE,
});

export const showFilter = () => ({
    type: ActionTypes.UI_FILTER_SHOW,
});

export const showRelativeKdDropdown = () => ({
    type: ActionTypes.UI_RELATIVE_KD_DROPDOWN_TOGGLE,
});

export const changeSerpColumnsSettings = (payload) => ({
    type: ActionTypes.UI_SERP_COLUMNS_SETTINGS_CHANGE,
    payload,
});

export const changeSerpColumnsSettingsDropdownVisible = (payload) => ({
    type: ActionTypes.UI_SERP_COLUMNS_SETTINGS_DROPDOWN_VISIBLE,
    payload
})

export const changeKeywordColumnsSettings = (payload) => ({
    type: ActionTypes.UI_KEYWORD_COLUMNS_SETTINGS_CHANGE,
    payload,
});

export const changeKeywordColumnsSettingsDropdownVisible = (payload) => ({
    type: ActionTypes.UI_KEYWORD_COLUMNS_SETTINGS_DROPDOWN_VISIBLE,
    payload
})

export const setCurrentKeywordId = payload => ({
    type: ActionTypes.UI_DASHBOARD_CURRENT_KEYWORD_ID_SET,
    error: false,
    payload,
});

export const setSerpItemActionsDropdownVisibleId = payload => ({
    type: ActionTypes.UI_DASHBOARD_SERP_ITEM_ACTIONS_DROPDOWN_VISIBLE_ID_SET,
    error: false,
    payload,
});

export const showLongerSerpLoadingNotification = () => ({
    type: ActionTypes.UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_SHOW,
});

export const hideLongerSerpLoadingNotification = () => ({
    type: ActionTypes.UI_MISC_LONGER_SERP_LOADING_NOTIFICATION_HIDE,
});

export const receivedErrorNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_ERROR_SHOW,
    error: false,
    payload: { message, config },
});

export const receivedInfoNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_INFO_SHOW,
    error: false,
    payload: { message, config },
});

export const requestedCloseAllNotificationsAction = () => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_CLOSE_ALL,
});

export const setDashboardLeftLoaderProgress = (count, finished) => ({
    type: ActionTypes.UI_DASHBOARD_LEFT_LOADER_PROGRESS_SET,
    error: false,
    payload: { count, finished },
});

export const setKeywordSource = ({ id, name, type }) => ({
    type: ActionTypes.UI_DASHBOARD_KEYWORD_SOURCE_SET,
    error: false,
    payload: {
        id,
        name,
        type,
    },
});

export const showExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_SHOW,
});

export const closeExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE,
});

export const setExporting = payload => ({
    type: ActionTypes.UI_MISC_EXPORTING_SET,
    error: false,
    payload,
});

export const closeHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_CLOSE,
});

export const showHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_SHOW,
});

export const closeLinksDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LINKS_CLOSE,
});

export const showLinksDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LINKS_SHOW,
});

export const closeTopLevelAnnouncementMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE,
});

export const showListActionsDropdown = listId => ({
    type: ActionTypes.UI_DROPDOWNS_LIST_ACTIONS_SHOW,
    error: false,
    payload: listId,
});

export const closeListActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LIST_ACTIONS_CLOSE,
});

export const showListDetailActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LIST_DETAIL_ACTIONS_SHOW,
});

export const closeListDetailActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LIST_DETAIL_ACTIONS_CLOSE,
});

export const setOnlineStatus = ({ onlineStatus }) => ({
    type: ActionTypes.UI_MISC_ONLINE_STATUS_SET,
    error: false,
    payload: {
        onlineStatus,
    },
});

export const showNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW,
});

export const closeNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE,
});

export const showLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW,
});

export const closeLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE,
});

export const showUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW,
});

export const closeUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE,
});

export const setQuickFilterSearch = payload => ({
    type: ActionTypes.UI_FILTER_QUICK_SETTINGS_SEARCH,
    payload,
});

export const showShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW,
});

export const closeShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE,
});

export const setAddToListKwIds = payload => ({
    type: ActionTypes.UI_MESSAGES_ADD_TO_LIST_ADDING_KW_IDS_SET,
    payload,
});

export const setColorScheme = payload => ({
    type: ActionTypes.UI_MISC_COLOR_SCHEME_SET,
    payload,
});

export const setCurrency = payload => ({
    type: ActionTypes.UI_MISC_CURRENCY_SET,
    payload,
});

export const showCurrenciesMessage = () => ({
    type: ActionTypes.UI_MESSAGES_CURRENCIES_SHOW,
});

export const closeCurrenciesMessage = () => ({
    type: ActionTypes.UI_MESSAGES_CURRENCIES_CLOSE,
});

export const showTrackInSerpwatcherMessage = listId => ({
    type: ActionTypes.UI_MESSAGES_TRACK_IN_SW_SHOW,
    payload: listId,
});

export const closeTrackInSerpwatcherMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TRACK_IN_SW_CLOSE,
});

export const setSelectedSearchVolumeTimeframe = timeframe => ({
    type: ActionTypes.UI_DASHBOARD_SELECTED_SEARCH_VOLUME_TIMEFRAME_SET,
    payload: timeframe,
});

export const setRelativeKdActive = payload => ({
    type: ActionTypes.UI_RELATIVE_KD_ACTIVE_SET,
    error: false,
    payload,
});


export const showKeywordGapMessage = () => ({
    type: ActionTypes.UI_MESSAGES_KEYWORD_GAP_SHOW,
});

export const closeKeywordGapMessage = () => ({
    type: ActionTypes.UI_MESSAGES_KEYWORD_GAP_CLOSE,
});