import React, { Component } from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import { GlobalHotKeys } from 'react-hotkeys';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';

import Overlay from 'components/layout/Overlay';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import MessageContainer from 'components/messages/MessageContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll, closeShortcutsMessage, showShortcutsMessage } from 'actions/uiActions';
import { gtmTrack } from 'actions/analyticsActions';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';
import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    areShortcutsDisabledSelector,
} from 'selectors/uiSelectors';

import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';
import { analyticsEvents, analyticsActions } from 'constants/analytics';
import { IssueWarningBanner } from 'mangools-react-components/src';

/**
 * Application layout component.
 */
class LayoutContainer extends Component {
    constructor(props) {
        super(props);
        this.handleToggleShortucts = this.handleToggleShortucts.bind(this);
        this.handleCloseAllShortcut = this.handleCloseAllShortcut.bind(this);
    }

    handleToggleShortucts() {
        const { onShowShortcutsMessage, onCloseShortcutsMessage } = this.props;

        if (this.props.shortcutsMessageVisible) {
            onCloseShortcutsMessage();
        } else {
            onShowShortcutsMessage();
        }
    }

    handleCloseAllShortcut() {
        this.props.onGtmTrack({
            action: analyticsActions.CLOSE_ALL,
            event: analyticsEvents.KB_SHORTCUT,
        });
        this.props.onCloseAll();
    }

    render() {
        const appClasses = classnames({
            'is-offline': this.props.onlineStatus === false,
            'kwfinder-app': true,
            'is-messaged': this.props.topLevelMessageVisible,
        });

        const { areShortcutsDisabled } = this.props;

        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [CLOSE_ALL]: { func: this.handleCloseAllShortcut, preventDisable: true },
                [SHOW_SHORTCUTS_TOGGLE]: { func: this.handleToggleShortucts },
            },
            areShortcutsDisabled,
            shortcuts,
        });

        return (
            <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}>
                <div className={appClasses}>
                    <TopLevelMessageContainer />
                    <NavbarContainer currentRoute={this.props.location.pathname} />
                    {/*<IssueWarningBanner />*/}
                    <div>{this.props.children}</div>
                    <PanelContainer />
                    <MessageContainer />
                    <Overlay mobile={false} onClick={this.props.onCloseAll} visibility={this.props.overlayVisibility} />
                    <Overlay mobile onClick={this.props.onCloseAll} visibility={this.props.mobileOverlayVisibility} />
                    <div className="uk-hidden-small">
                        <Alert effect="slide" offset={50} position="top-right" stack={{ limit: 3 }} timeout={3500} />
                    </div>
                </div>
            </GlobalHotKeys>
        );
    }
}

LayoutContainer.propTypes = {
    children: element.isRequired,
    location: shape({ pathname: string }).isRequired,
    mobileOverlayVisibility: bool.isRequired,
    onCloseAll: func.isRequired,
    onlineStatus: bool.isRequired,
    overlayVisibility: bool.isRequired,
    topLevelMessageVisible: bool.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    onShowShortcutsMessage: func.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    areShortcutsDisabled: bool.isRequired,
    onGtmTrack: func.isRequired,
};

const mapStateToProps = (state, _ownProps) => ({
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    topLevelMessageVisible: newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    areShortcutsDisabled: areShortcutsDisabledSelector(state),
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onCloseAll() {
        dispatch(closeAll());
    },
    onShowShortcutsMessage() {
        dispatch(showShortcutsMessage());
        dispatch(
            gtmTrack({
                action: analyticsActions.SHOW_SHORTCUTS_TOGGLE,
                event: analyticsEvents.KB_SHORTCUT,
            }),
        );
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onGtmTrack(payload) {
        dispatch(gtmTrack(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
