import { createSelector } from 'reselect';

import {
    pipe,
    concat,
    includes,
    find,
    forEach,
    isEmpty,
    isNil,
    propEq,
    prop,
    reduce,
    reverse,
    sortBy,
    sum,
} from 'ramda';
import update from 'immutability-helper';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import { ANY_LANGUAGE } from 'mangools-commons/lib/constants/Languages';

import { ANNOUNCEMENT_EMPTY_MESSAGE } from 'reducers/data/announcementsReducer';
import { DEFAULT_RELATED_ROWS_MAX_LIMIT, DEFAULT_COMPETITOR_ROWS_MAX_LIMIT } from 'reducers/userReducer';

import ResultFilterService from 'services/ResultFilterService';
import ResultSorterService from 'services/ResultSorterService';

import {
    currentKeywordIdSelector,
    filterActiveSelector,
    filterSettingsSelector,
    filterQuickSettingsSelector,
    keywordSourceResourceIdSelector,
    listPanelFilterSelector,
    selectedKeywordIdsSelector,
    sortingSettingsSelector,
    currencySelector,
    relativeKdActiveSelector,
} from 'selectors/uiSelectors';

import {
    competitorRowsLimitSelector,
    fakeResultsDisabledSelector,
    keywordSourceResourceTypeSelector,
    limitsFetchedSelector,
    relatedRowsLimitSelector,
    resultsDataSelector,
    topLevelAnnouncementMessageVisibilitySelector,
    selectedSearchVolumeTimeframeTypeSelector,
} from 'selectors/sharedSelectors';

import {
    defaultCompetitorLocationSelector,
    defaultGoogleLanguageSelector,
    defaultGoogleLocationSelector,
    defaultSharedDataSubSourceSelector,
    defaultCompetitorQuerySelector,
    defaultGoogleQuerySelector,
} from 'selectors/defaultsSelectors';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import KeywordSourceTypes, { GOOGLE_TYPES } from 'constants/KeywordSourceTypes';
import DataSubSourceTypes from 'constants/DataSubSourceTypes';
import KeywordsByDomainTypes from 'constants/KeywordsByDomainTypes';
import searchVolumeTimeframeTypes from 'constants/SearchVolumeTimeframeTypes';
import { FEATURE_TYPES } from 'constants/SerpFeatureTypes';
import { urlDataLpsSelector } from 'selectors/urlDataSelectors';

// Lists
export const fetchingListsDataSelector = state => state.data.lists.fetching;
const fetchingSerpResultsMoreSelector = state => state.data.serpResults.fetchingMore;
const listsDataSelector = state => state.data.lists.data;

// Misc
export const rateLimitedSelector = state => state.data.results.rateLimited;
export const currentDataParamsSelector = state => state.data.results.currentDataParams;
export const resultsLastPayloadSelector = state => state.data.results.lastPayload;

// Locations
export const locationsDataSelector = state => state.data.locations.data;
export const fetchingLocationsDataSelector = state => state.data.locations.fetching;

// Competitor selectors
export const competitorsSelector = state => state.data.competitors;
export const competitorsDataSelector = state => state.data.competitors.data;
export const fetchingCompetitorsDataSelector = state => state.data.competitors.fetching;
export const competitorsCurrentDataParamsSelector = createSelector(competitorsSelector, prop('currentDataParams'));

// Currencies
export const allCurrenciesSelector = state => state.data.currencies.data;
export const currenciesFetchingSelector = state => state.data.currencies.fetching;
export const currenciesSelector = createSelector(
    [allCurrenciesSelector, currencySelector],
    (allCurrencies, selectedCurrency) => {
        if (!isNil(allCurrencies)) {
            const topCurrencyCodes = ['USD', 'GBP', 'EUR'].filter(
                currencyCode => currencyCode !== selectedCurrency.code,
            );
            // const topCurrencies = topCurrencyCodes
            //     .map(currencyCode => allCurrencies.find(currency => currency.code === currencyCode));
            const topCurrencies = allCurrencies.filter(currency => includes(currency.code, topCurrencyCodes));
            const currencies = allCurrencies.filter(
                currency => currency.code !== selectedCurrency.code && !includes(currency.code, topCurrencyCodes),
            );

            return [selectedCurrency, ...topCurrencies, ...currencies];
        } else {
            return null;
        }
    },
);

// Trends
const trendRawDataSelector = state => state.data.trends.data;
export const trendDataFetchingSelector = state => state.data.trends.fetching;
export const trendMetaDataSelector = state => state.data.trends.meta;

export const trendDataSelector = createSelector([trendRawDataSelector], data =>
    data.filter(item => !isNil(item.value) || item.value === 0),
);

// Serps
export const serpResultsDataSelector = state => {
    if (state.data.serpResults.data.length > 0) {
        // TODO: remove MAP PACK filter once new UI is ready
        return state.data.serpResults.data.filter(
            item => ![FEATURE_TYPES.LOCAL_PACK, FEATURE_TYPES.MAP_PACK].includes(item.type),
        );
    }

    return state.data.serpResults.data;
};
export const fetchingSerpResultsSelector = state => state.data.serpResults.fetching;
export const nextSerpPageSelector = state => state.data.serpResults.nextPage;

export const serpResultsLoadingSelector = createSelector(
    [fetchingSerpResultsSelector, fetchingSerpResultsMoreSelector],
    (fetching, fetchingMore) => fetching || fetchingMore,
);

// Competitor kw selectors

export const competitorKeywordsCountsSelector = createSelector(
    [resultsDataSelector, keywordSourceResourceTypeSelector],
    (resultsData, keywordSource) => {
        if (keywordSource === KeywordSourceTypes.COMPETITOR) {
            const organicKeywords = resultsData.filter(item => item.keywordType === KeywordsByDomainTypes.ORGANIC);
            const paidKeywords = resultsData.filter(item => item.keywordType === KeywordsByDomainTypes.PAID);

            return {
                organic: organicKeywords.length,
                paid: paidKeywords.length,
            };
        } else {
            return {
                organic: 0,
                paid: 0,
            };
        }
    },
);

// More results selector

// NOTE: Returns true if there might be more keywords available.
// There is a possible false-positive when the amount of returned
// keywords == the keyword rows limit.
export const moreKeywordsAvailableSelector = createSelector(
    [
        fakeResultsDisabledSelector,
        filterActiveSelector,
        resultsDataSelector,
        competitorRowsLimitSelector,
        relatedRowsLimitSelector,
        keywordSourceResourceTypeSelector,
        defaultSharedDataSubSourceSelector,
    ],
    (fakeResultsDisabled, filterActive, data, competitorRows, relatedRows, keywordSource, subSource) => {
        if (fakeResultsDisabled === true || filterActive === true) {
            return false;
        } else if (keywordSource === KeywordSourceTypes.COMPETITOR) {
            let filtered = [];

            if (subSource === DataSubSourceTypes.COMPETITORS_ORGANIC) {
                filtered = data.filter(item => item.keywordType === KeywordsByDomainTypes.ORGANIC);
            } else {
                filtered = data.filter(item => item.keywordType === KeywordsByDomainTypes.PAID);
            }

            return filtered.length === competitorRows && competitorRows < DEFAULT_COMPETITOR_ROWS_MAX_LIMIT;
        } else if (includes(keywordSource, GOOGLE_TYPES)) {
            return data.length === relatedRows && relatedRows < DEFAULT_RELATED_ROWS_MAX_LIMIT;
        } else {
            return false;
        }
    },
);

// Result data selectors, filtered/sorted/in lists/etc.

// NOTE: Creates a memoized map of all keywords in all lists
// for easy O(1) check if kw is in some list.
// Map example: {
//     kwid01: [ { id: asndsa, name: 'listName' }, { id: dsads, name: 'listName2' } ]
//     ...
// }

export const keywordsInListsMapSelector = createSelector(
    [listsDataSelector, resultsDataSelector],
    (lists, keywords) => {
        // eslint-disable-next-line no-param-reassign
        keywords = keywords.reduce((kwIdMap, kw) => {
            // eslint-disable-next-line no-param-reassign
            kwIdMap[kw.id] = 1;
            return kwIdMap;
        }, {});

        const kwListMap = reduce(
            (map, val) => {
                const newMap = { ...map };
                const listId = val.id;
                const listName = val.name;
                const kwIds = val.keywordIds;

                // For each keyword ID in set object key
                // and a value is an array of list objects in which that kw in contained
                forEach(kwId => {
                    if (keywords[kwId] === 1) {
                        if (isNil(newMap[kwId])) {
                            newMap[kwId] = [
                                {
                                    // eslint-disable-line no-param-reassign
                                    id: listId,
                                    name: listName,
                                },
                            ];
                        } else {
                            newMap[kwId] = concat(newMap[kwId], [
                                {
                                    // eslint-disable-line no-param-reassign
                                    id: listId,
                                    name: listName,
                                },
                            ]);
                        }
                    }
                }, kwIds);

                return newMap;
            },
            {},
            lists,
        );

        return kwListMap;
    },
);


// content types
export const contentTypeRootSelector = state => state.data.contentTypes;
export const fetchingContentTypesSelector = createSelector(
    [contentTypeRootSelector], contentTypeState => contentTypeState.fetching
);

export const dataContentTypesSelector = createSelector(
    [contentTypeRootSelector], contentTypeState => contentTypeState.data
);

export const searchIntentRootSelector = state => state.data.searchIntent;
export const fetchingSearchIntentSelector = createSelector(
    [searchIntentRootSelector], searchIntentState => searchIntentState?.fetching
);
export const searchIntentErrorSelector = createSelector(
    [searchIntentRootSelector], searchIntentState => searchIntentState?.data
);

export const dataAllSearchIntentsSelector = createSelector(
    [searchIntentRootSelector],
    (searchIntentState) => searchIntentState?.data?.data || {}
);

export const dataSearchIntentSelector = (keywordId) => createSelector(
    [dataAllSearchIntentsSelector],
    (data) => data?.[keywordId] || []
);


export const filteredAndSortedResultsDataSelector = createSelector(
    [
        resultsDataSelector,
        keywordsInListsMapSelector,
        filterActiveSelector,
        filterSettingsSelector,
        sortingSettingsSelector,
        keywordSourceResourceTypeSelector,
        defaultSharedDataSubSourceSelector,
        filterQuickSettingsSelector,
        currencySelector,
        selectedSearchVolumeTimeframeTypeSelector,
        urlDataLpsSelector,
        relativeKdActiveSelector,
        dataContentTypesSelector,
        dataAllSearchIntentsSelector
    ],
    (
        resultsData,
        keywordsInListsMap,
        filterActive,
        filterSettings,
        sortingSettings,
        keywordSource,
        dataSubSource,
        quickSettings,
        currency,
        selectedSearchVolumeTimeframe,
        urlDataLps,
        relativeKdActive,
        contentTypes,
        searchIntent
    ) => {
        let data = resultsData;

        if (keywordSource === KeywordSourceTypes.COMPETITOR) {
            if (dataSubSource === DataSubSourceTypes.COMPETITORS_ORGANIC) {
                data = data.filter(item => item.keywordType === KeywordsByDomainTypes.ORGANIC);
            } else if (dataSubSource === DataSubSourceTypes.COMPETITORS_PAID) {
                data = data.filter(item => item.keywordType === KeywordsByDomainTypes.PAID);
            }
        }

        const searchVolumeTF =
            keywordSource === KeywordSourceTypes.COMPETITOR
                ? searchVolumeTimeframeTypes.LAST_12_MONTHS
                : selectedSearchVolumeTimeframe;

        const filteredData =
            data.length === 0
                ? data
                : ResultFilterService.filter({
                    data,
                    keywordsInListsMap,
                    settings: filterSettings,
                    keywordSource,
                    quickSettings,
                    filterActive,
                    currency,
                    selectedSearchVolumeTimeframe: searchVolumeTF,
                    urlDataLps,
                    relativeKdActive,
                });

        const sortedData = ResultSorterService.sort({
            data: filteredData,
            settings: sortingSettings,
            selectedSearchVolumeTimeframe: searchVolumeTF,
        });

        let finalData = sortedData.map((item) => {
            return {
                ...item,
                contentTypes: contentTypes[item.id],
                searchIntent: searchIntent[item.id]
            }
        })

        if (filterActive) {
            // We want to filter data after theirs joining, but we don't want to join data before initial filtering
            if (filterSettings.includedContentTypes.length > 0) {
                const filterValues = new Set(filterSettings.includedContentTypes.map(({ value }) => value));

                finalData = finalData.filter((d) => {
                    const hasSearch = filterValues.has("SEARCH") && d.contentTypes === undefined;
                    const hasUnknown = filterValues.has("UNKNOWN") && d.contentTypes?.length === 0;
                    const hasMatchingType = d.contentTypes?.some((type) => filterValues.has(type));

                    return hasSearch || hasUnknown || hasMatchingType;
                });
            }

            if (filterSettings.includedSearchIntentTypes.length !== 0) {
                const filterValues = filterSettings.includedSearchIntentTypes.map(({ value }) => value)
                finalData = finalData.filter((d) =>
                    (d.searchIntent ?? []).some((type) => filterValues.includes(type.toUpperCase()))
                );
            }
        }

        return finalData;
    },
);

export const filteredAndSortedSelectedKeywordIdsSelector = createSelector(
    [selectedKeywordIdsSelector, filteredAndSortedResultsDataSelector],
    (selectedIds, filteredAndSortedData) => {
        const keywordsIds = filteredAndSortedData.map(item => item.id);

        const selectedIdsObj = reduce(
            (obj, val) => {
                obj[val] = true; // eslint-disable-line no-param-reassign
                return obj;
            },
            {},
            selectedIds,
        );

        return keywordsIds.filter(id => selectedIdsObj[id] !== undefined);
    },
);

export const filteredAndSortedSelectedKeywordIdsWithRankUpdatedNotInLastTwentyFourHoursSelector = createSelector(
    [selectedKeywordIdsSelector, filteredAndSortedResultsDataSelector],
    (selectedIds, filteredAndSortedData) => {
        const keywordsIds = filteredAndSortedData
            .filter(keyword => {
                const updatedAtMilis = keyword.rankUpdatedAt * 1000;
                const gmtNoon = new Date().setUTCHours(0, 0, 0, 0);
                return updatedAtMilis < gmtNoon;
            })
            .map(item => item.id);

        const selectedIdsObj = reduce(
            (obj, val) => {
                obj[val] = true; // eslint-disable-line no-param-reassign
                return obj;
            },
            {},
            selectedIds,
        );

        return keywordsIds.filter(id => selectedIdsObj[id] !== undefined);
    },
);

export const filteredAndSortedSelectedKeywordsSelector = createSelector(
    [selectedKeywordIdsSelector, filteredAndSortedResultsDataSelector],
    (ids, sortedAndFilteredKeywords) => sortedAndFilteredKeywords.filter(kw => includes(kw.id, ids)),
);

export const filteredAndSortedSelectedKeywordStringsSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    selectedKeywords => selectedKeywords.map(kw => kw.keyword),
);

export const filteredAndSortedSelectedKeywordsSearchCountTotalSelector = createSelector(
    [
        filteredAndSortedSelectedKeywordsSelector,
        selectedSearchVolumeTimeframeTypeSelector,
        keywordSourceResourceTypeSelector,
    ],
    (keywords, selectedSearchVolumeTimeframe, keywordSourceType) => {
        return reduce(
            (count, { searchVolumesByTimeframe }) => {
                let selectedSearchVolumeTimeframeCount;

                if (keywordSourceType === KeywordSourceTypes.COMPETITOR) {
                    selectedSearchVolumeTimeframeCount = prop(
                        searchVolumeTimeframeTypes.LAST_12_MONTHS,
                        searchVolumesByTimeframe,
                    );
                } else {
                    selectedSearchVolumeTimeframeCount = prop(selectedSearchVolumeTimeframe, searchVolumesByTimeframe);
                }

                if (selectedSearchVolumeTimeframeCount > 0) {
                    return count + selectedSearchVolumeTimeframeCount;
                } else {
                    return count;
                }
            },
            0,
            keywords,
        );
    },
);

export const filteredAndSortedSelectedKeywordsAvgRankSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    keywords => {
        const withRank = keywords.filter(kw => kw.rank > 0);
        const ranks = withRank.map(kw => kw.rank);

        if (withRank.length > 0) {
            return sum(ranks) / withRank.length;
        } else {
            return 0;
        }
    },
);

export const filteredAndSortedSelectedKeywordsAvgCpcSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    keywords => {
        const withCpc = keywords.filter(kw => kw.cpc > 0);
        const cpcs = withCpc.map(kw => kw.cpc);

        if (withCpc.length > 0) {
            return sum(cpcs) / withCpc.length;
        } else {
            return 0;
        }
    },
);

export const filteredAndSortedSelectedKeywordsAvgPpcSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    keywords => {
        const withPpc = keywords.filter(kw => kw.ppc > 0);
        const ppcs = withPpc.map(kw => kw.ppc);

        if (withPpc.length > 0) {
            return sum(ppcs) / withPpc.length;
        } else {
            return 0;
        }
    },
);

export const filteredAndSortedSelectedKeywordsEtTotalSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    keywords => {
        const filteredKeywords = keywords.filter(kw => kw.estimatedTraffic > 0);
        return sum(filteredKeywords.map(kw => kw.estimatedTraffic));
    },
);

export const filteredAndSortedSelectedKeywordsAvgSpSelector = createSelector(
    [filteredAndSortedSelectedKeywordsSelector],
    keywords => {
        const withSp = keywords.filter(kw => kw.serpPosition > 0);
        const values = withSp.map(kw => kw.serpPosition);

        if (withSp.length > 0) {
            return sum(values) / withSp.length;
        } else {
            return 0;
        }
    },
);

export const allKeywordsSearchCountTotalSelector = createSelector(
    [resultsDataSelector, selectedSearchVolumeTimeframeTypeSelector, keywordSourceResourceTypeSelector],
    (keywords, selectedSearchVolumeTimeframe, keywordSourceType) => {
        return reduce(
            (count, { searchVolumesByTimeframe }) => {
                let selectedSearchVolumeTimeframeCount;

                if (keywordSourceType === KeywordSourceTypes.COMPETITOR) {
                    selectedSearchVolumeTimeframeCount = prop(
                        searchVolumeTimeframeTypes.LAST_12_MONTHS,
                        searchVolumesByTimeframe,
                    );
                } else {
                    selectedSearchVolumeTimeframeCount = prop(selectedSearchVolumeTimeframe, searchVolumesByTimeframe);
                }

                if (selectedSearchVolumeTimeframeCount > 0) {
                    return count + selectedSearchVolumeTimeframeCount;
                } else {
                    return count;
                }
            },
            0,
            keywords,
        );
    },
);

export const allKeywordsAvgRankSelector = createSelector([resultsDataSelector], keywords => {
    const withRank = keywords.filter(kw => kw.rank > 0);
    const ranks = withRank.map(kw => kw.rank);

    if (withRank.length > 0) {
        return sum(ranks) / withRank.length;
    } else {
        return 0;
    }
});

export const allKeywordsAvgCpcSelector = createSelector([resultsDataSelector], keywords => {
    const withCpc = keywords.filter(kw => kw.cpc > 0);
    const cpcs = withCpc.map(kw => kw.cpc);

    if (withCpc.length > 0) {
        return sum(cpcs) / withCpc.length;
    } else {
        return 0;
    }
});

export const allKeywordsAvgPpcSelector = createSelector([resultsDataSelector], keywords => {
    const withPpc = keywords.filter(kw => kw.ppc > 0);
    const ppcs = withPpc.map(kw => kw.ppc);

    if (withPpc.length > 0) {
        return sum(ppcs) / withPpc.length;
    } else {
        return 0;
    }
});

export const allKeywordsEstimatedTrafficTotalSelector = createSelector([resultsDataSelector], keywords => {
    const filteredKeywords = keywords.filter(kw => kw.estimatedTraffic > 0);
    return sum(filteredKeywords.map(kw => kw.estimatedTraffic));
});

export const allKeywordsAvgSerpPositionSelector = createSelector([resultsDataSelector], keywords => {
    const withSp = keywords.filter(kw => kw.serpPosition > 0);
    const values = withSp.map(kw => kw.serpPosition);

    if (withSp.length > 0) {
        return sum(values) / withSp.length;
    } else {
        return 0;
    }
});

export const allKeywordIdsSelector = createSelector([resultsDataSelector], keywords => keywords.map(kw => kw.id));

// Current keyword id based selectors

export const currentKeywordSelector = createSelector(
    [currentKeywordIdSelector, resultsDataSelector],
    (id, resultsData) => {
        const result = find(propEq('id', id))(resultsData);
        return isNil(result) ? null : result;
    },
);

export const keywordStringSelector = createSelector(
    [currentKeywordIdSelector, resultsDataSelector],
    (id, resultsData) => {
        const result = find(propEq('id', id))(resultsData);
        return isNil(result) ? null : result.keyword;
    },
);

export const keywordSearchVolumesSelector = createSelector(
    [currentKeywordIdSelector, resultsDataSelector],
    (id, resultsData) => {
        const result = find(propEq('id', id))(resultsData);
        return isNil(result) ? [] : result.monthlySearchVolumes;
    },
);

export const keywordLatestSearchVolumeSelector = createSelector(
    [currentKeywordIdSelector, resultsDataSelector],
    (id, resultsData) => {
        const result = find(propEq('id', id))(resultsData);
        if (isNil(result) || isNil(result.searchCountLatest)) {
            return null;
        } else {
            return result.searchCountLatest;
        }
    },
);

export const keywordLanguageSelector = createSelector(
    [currentKeywordIdSelector, resultsDataSelector, keywordSourceResourceTypeSelector, defaultGoogleLanguageSelector],
    (id, resultsData, keywordSourceType, defaultGoogleLanguage) => {
        const result = find(propEq('id', id))(resultsData);

        if (isNil(result)) {
            if (keywordSourceType === KeywordSourceTypes.COMPETITOR) {
                return ANY_LANGUAGE;
            } else {
                return defaultGoogleLanguage;
            }
        } else {
            return result.language;
        }
    },
);

export const keywordLocationSelector = createSelector(
    [
        currentKeywordIdSelector,
        resultsDataSelector,
        keywordSourceResourceTypeSelector,
        defaultGoogleLocationSelector,
        defaultCompetitorLocationSelector,
    ],
    (id, resultsData, keywordSourceType, defaultGoogleLocation, defaultCompetitorLocation) => {
        const result = find(propEq('id', id))(resultsData);

        if (isNil(result)) {
            if (keywordSourceType === KeywordSourceTypes.COMPETITOR) {
                return defaultCompetitorLocation;
            } else {
                return defaultGoogleLocation;
            }
        } else {
            return result.location;
        }
    },
);

export const rankSelector = createSelector([currentKeywordIdSelector, resultsDataSelector], (id, resultsData) => {
    const result = find(propEq('id', id))(resultsData);
    return isNil(result) ? Defaults.NO_VALUE : result.rank;
});

export const msvTrendsSelector = createSelector([currentKeywordIdSelector, resultsDataSelector], (id, resultsData) => {
    const result = find(propEq('id', id))(resultsData);
    return isNil(result) ? [] : result.msvTrends;
});

export const searchCountSelector = createSelector(
    [
        currentKeywordIdSelector,
        resultsDataSelector,
        selectedSearchVolumeTimeframeTypeSelector,
        keywordSourceResourceTypeSelector,
    ],
    (id, resultsData, selectedSearchVolumeTimeframe, keywordSourceType) => {
        return pipe(find(propEq('id', id)), keyword => {
            if (isNil(keyword)) {
                return 0;
            } else if (keywordSourceType === KeywordSourceTypes.COMPETITOR) {
                return keyword.searchVolumesByTimeframe[searchVolumeTimeframeTypes.LAST_12_MONTHS];
            } else {
                return keyword.searchVolumesByTimeframe[selectedSearchVolumeTimeframe];
            }
        })(resultsData);
    },
);

// List selectors

export const sortedListDataSelector = createSelector([listsDataSelector], lists => {
    const sorted = sortBy(prop('updatedAt'))(lists);
    return reverse(sorted);
});

export const filteredAndSortedListPanelDataSelector = createSelector(
    [sortedListDataSelector, listPanelFilterSelector],
    (lists, listNameFilter) => {
        if (isNil(listNameFilter) || listNameFilter === '') {
            return lists;
        } else {
            const sanitizedFilter = listNameFilter.trim().toLowerCase();
            return lists.filter(({ name }) => name.toLowerCase().includes(sanitizedFilter));
        }
    },
);

// Current list selectors

export const listKeywordIdsSelector = (state, listId) => {
    const lists = state.data.lists.data;
    const list = find(propEq('id', listId))(lists);

    return !isNil(list) ? list.keywordIds : [];
};

export const listSelector = (state, listId) => {
    const lists = state.data.lists.data;

    return find(propEq('id', listId))(lists);
};

export const lastUsedOrCreatedListSelector = createSelector([listsDataSelector], lists => {
    if (!isEmpty(lists)) {
        const sorted = sortBy(prop('updatedAt'))(lists);
        return reverse(sorted)[0];
    } else {
        return null;
    }
});

export const currentListDetailDataSelector = createSelector(
    [listsDataSelector, keywordSourceResourceIdSelector, keywordSourceResourceTypeSelector],
    (lists, listId, keywordSourceType) => {
        if (keywordSourceType === KeywordSourceTypes.LIST) {
            return find(propEq('id', listId))(lists);
        } else {
            return null;
        }
    },
);

export const currentListDetailNameSelector = createSelector([currentListDetailDataSelector], list => {
    if (!isNil(list)) {
        return list.name;
    } else {
        return null;
    }
});

export const currentListDetailIdSelector = createSelector([currentListDetailDataSelector], list => {
    if (!isNil(list)) {
        return list.id;
    } else {
        return null;
    }
});

// Announcements
const announcementsDataSelector = state => state.data.announcements.data;
const fetchingAnnouncementsDataSelector = state => state.data.announcements.fetching;

export const announcementsMessageDataSelector = createSelector([announcementsDataSelector], announcementsData => {
    // TODO: Remove after debugging
    // return {
    //     activeFrom: 0,
    //     activeTo: 0,
    //     btnText: 'Read more',
    //     icon: 'rocket',
    //     messageText: 'We\'ve launched the competitor analysis in KWFinder',
    //     newTab: false,
    //     url: 'http://mangools.com/blog'
    // };

    if (isNil(announcementsData)) {
        return ANNOUNCEMENT_EMPTY_MESSAGE;
    } else {
        return announcementsData;
    }
});

export const announcementMesageVisibilitySelector = createSelector(
    [
        fetchingAnnouncementsDataSelector,
        announcementsMessageDataSelector,
        limitsFetchedSelector,
        topLevelAnnouncementMessageVisibilitySelector,
        newUserMessageVisibilitySelector,
    ],
    (fetchingAnnouncements, announcementsMessageData, fetchedLimits, announcementsVisible, newUserMessageVisible) => {
        if (
            fetchedLimits === true &&
            fetchingAnnouncements === false &&
            announcementsVisible === true &&
            newUserMessageVisible === false
        ) {
            // TODO: Remove after debugging
            // return true;

            // There is an announcement which is active
            if (announcementsMessageData.activeTo > Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);

export const exportSelectedKeywordsFilenameSelector = createSelector(
    [
        keywordSourceResourceTypeSelector,
        defaultGoogleQuerySelector,
        defaultCompetitorQuerySelector,
        currentListDetailNameSelector,
    ],
    (kwSourceType, googleQuery, competitorQuery, listName) => {
        if (GOOGLE_TYPES.includes(kwSourceType)) {
            return googleQuery;
        } else if (kwSourceType === KeywordSourceTypes.COMPETITOR) {
            return competitorQuery;
        } else if (kwSourceType === KeywordSourceTypes.LIST) {
            return listName;
        } else if (kwSourceType === KeywordSourceTypes.IMPORT) {
            return 'import';
        } else {
            return '';
        }
    },
);

export const newCurrentKwIdSelector = createSelector(
    [filteredAndSortedSelectedKeywordIdsSelector, allKeywordIdsSelector],
    (selectedKwIds, allKeywordIds) => {
        const keywordIds = allKeywordIds.filter(id => !selectedKwIds.includes(id));

        return keywordIds.length === 0 ? null : keywordIds[0];
    },
);

export const allKeywordsSelectedSelector = createSelector(
    [filteredAndSortedResultsDataSelector, selectedKeywordIdsSelector],
    (data, selectedKwIds) => data.length > 0 && data.length === selectedKwIds.length,
);
// history

export const historyDataSelector = state => {
    return state.data.history.data.map((entry, index) => {
        return update(entry, { $merge: { key: `${entry.id}-${index}` } });
    });
};

export const fetchingSerpwatcherTrackingsSelector = state => state.data.serpwatcherTrackings.fetching;
export const serpwatcherTrackingsDataSelector = state => {
    return {
        swTrackingsById: state.data.serpwatcherTrackings.data.byId,
        swTrackingsAllIds: state.data.serpwatcherTrackings.data.allIds,
    };
};

export const getFirstRowFromDataResultsSelector = createSelector([resultsDataSelector], resultsData => resultsData[0]);

export const getKdHistoryDataSelector = createSelector(
    [state => state.data.kdHistory.data],
    kdHistoryData => kdHistoryData,
);
