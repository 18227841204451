import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { eventChannel } from 'redux-saga';
import { delay, all, call, put, race, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { concat, isEmpty, isNil, slice, uniq, find, propEq } from 'ramda';
import isURL from 'validator/lib/isURL';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import DownloaderService from 'mangools-commons/lib/services/DownloaderService';
import FileService from 'mangools-commons/lib/services/FileService';
import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';

import config from 'appConfig';

import { ListCreatedAlert, ListUpdatedAlert } from 'components/other/Alerts';

import AnnouncementsSource from 'sources/AnnouncementsSource';
import KeywordsByDomainResultSource from 'sources/KeywordsByDomainResultSource';
import CompetitorsByDomainResultSource from 'sources/CompetitorsByDomainResultSource';
import GoogleSource from 'sources/GoogleSource';
import HistorySource from 'sources/HistorySource';
import ImportSource from 'sources/ImportSource';
import ListSource from 'sources/ListSource';
import LocationSource from 'sources/LocationSource';
import ResultSource from 'sources/ResultSource';
import SerpResultSource from 'sources/SerpResultSource';
import TrendSource from 'sources/TrendSource';
import VersionSource from 'sources/VersionSource';
import CurrencyRatesSource from 'sources/CurrencyRatesSource';
import UnleashSource from 'sources/UnleashSource';

import { generateKeywords, generateQuestions } from 'services/GoogleQueryGeneratorService';

import ExportService from 'services/ExportService';

import { showInfoNotification, showErrorNotification } from 'sagas/uiSagas';

import { handleUncaught, logError } from 'sagas/errorSagas';

import {
    cancelledDeleteSelectedKeywordsFromCurrentListAction,
    cancelledListsDeleteAction,
    emptyKeywordsByDomainResultsAction,
    emptyCompetitorsByDomainResultsAction,
    emptyGoogleResultsAction,
    errorAnnouncementsAction,
    errorKeywordsByDomainResultsAction,
    errorCompetitorsByDomainResultsAction,
    errorDeleteSelectedKeywordsFromCurrentListAction,
    errorGoogleResultsAction,
    errorHistoryAction,
    errorImportAction,
    errorListKeywordsAction,
    errorListsAction,
    errorListsAddKeywordsAction,
    errorListsCreateAction,
    errorListsDeleteAction,
    errorListsRenameAction,
    errorLocationsAction,
    errorResultsAction,
    errorSerpResultsAction,
    errorSerpResultsMoreAction,
    errorTrendsAction,
    fetchingAnnouncementsAction,
    fetchingKeywordsByDomainResultsAction,
    fetchingCompetitorsByDomainResultsAction,
    fetchingGoogleResultsAction,
    fetchingHistoryAction,
    fetchingImportAction,
    fetchingListKeywordsAction,
    fetchingListsAction,
    fetchingListsDeleteAction,
    fetchingListsRenameAction,
    fetchingLocationsAction,
    fetchingResultsAction,
    fetchingSerpResultsAction,
    fetchingSerpResultsMoreAction,
    fetchingTrendsAction,
    finishedGoogleResultsAction,
    receivedAnnouncementsAction,
    receivedKeywordsByDomainResultsAction,
    receivedCompetitorsByDomainResultsAction,
    receivedHistoryAction,
    receivedImportAction,
    receivedKeywordRankAction,
    emptyKeywordRankAction,
    receivedListKeywordsAction,
    receivedListsAction,
    receivedListsAddKeywordsAction,
    receivedListsDeleteAction,
    receivedListsNewAction,
    receivedListsRenameAction,
    receivedLocationsAction,
    receivedNoMoreSerpResultsAction,
    receivedNoSerpResultsAction,
    receivedResultsAction,
    receivedSerpResultsAction,
    receivedSerpResultsMoreAction,
    receivedTrendsAction,
    requestedKeywordsImportAction,
    skippedKeywordsByDomainResultsAction,
    skippedCompetitorsByDomainResultsAction,
    skippedHistoryAction,
    skippedListsAction,
    errorDeleteHistoryAction,
    receivedDeleteHistoryAction,
    receivedCurrencyRatesAction,
    errorCurrencyRatesAction,
    fetchingCurrencyRatesAction,
    updateListsAddKeywords,
    revertUpdateListsAddKeywords,
    optimisticDeleteKeywordsFromListAction,
    optimisticDeleteKeywordFromListAction,
    revertOptimisticDeleteKeywordFromListAction,
    revertOptimisticDeleteKeywordsFromListAction,
    fetchingKdHistoryAction,
    receivedKdHistoryAction,
    errorKdHistoryAction,
    skippedKdHistoryAction,
    errorDeleteKdHistoryAction,
    receivedDeleteKdHistoryAction,
    fetchingUrlDataAction,
    receivedUrlDataAction,
    errorUrlDataAction,
    errorContentTypesAction,
    fetchingContentTypesAction,
    receivedContentTypesAction,
    receivedSearchIntentAction,
    fetchingSearchIntentAction,
    errorSearchIntentAction,
    receivedSingleSearchIntentAction,
} from 'actions/dataActions';

import {
    hideLongerSerpLoadingNotification,
    setCurrentKeywordId,
    setDashboardLeftLoaderProgress,
    setExporting,
    setFilterActive,
    setKeywordSource,
    setNewVersionNotificationShown,
    setQuickFilterSearch,
    showAccessDeniedMessage,
    showDeleteConfirmationMessage,
    showFailureMessage,
    showLongerSerpLoadingNotification,
    showNoConnectionMessage,
    unselectAllKeywords,
    setCurrency,
    setSortingSettings,
    showPricingMessage,
    showRelativeKdDropdown,
} from 'actions/uiActions';

import {
    setDefaultKeywordsByDomainLocation,
    setDefaultKeywordsByDomainQuery,
    setDefaultGoogleLanguage,
    setDefaultGoogleLocation,
    setDefaultGoogleQuery,
} from 'actions/defaultsActions';

import { requestedLimitsAction, setUnleashSessionAction } from 'actions/userActions';

import { requestedNavigationAction } from 'actions/routerActions';

import { setParams } from 'actions/paramsActions';

import { gtmTrack } from 'actions/analyticsActions';

import { keywordSourceResourceTypeSelector, resultsDataSelector } from 'selectors/sharedSelectors';

import {
    currentDataParamsSelector,
    currentKeywordSelector,
    exportSelectedKeywordsFilenameSelector,
    filteredAndSortedResultsDataSelector,
    filteredAndSortedSelectedKeywordIdsSelector,
    filteredAndSortedSelectedKeywordIdsWithRankUpdatedNotInLastTwentyFourHoursSelector,
    filteredAndSortedSelectedKeywordsSelector,
    listSelector,
    nextSerpPageSelector,
    trendMetaDataSelector,
    newCurrentKwIdSelector,
    currentListDetailDataSelector,
    competitorsCurrentDataParamsSelector,
} from 'selectors/dataSelectors';

import {
    addToListMessageAddingKeywordIdsSelector,
    currentKeywordIdSelector,
    keywordSourceResourceIdSelector,
    newVersionNotificationShownSelector,
    currencySelector,
    comparingBoxUrlSelector,
    comparingBoxUrlProtocolSelector,
    relativeKdActiveSelector,
    keywordColumnsSettingsVisibleSelector,
} from 'selectors/uiSelectors';

import {
    defaultGoogleLanguageSelector,
    defaultGoogleLocationSelector,
    defaultSharedCurrentKeywordGraphSelector,
} from 'selectors/defaultsSelectors';

import {
    accessTokenSelector,
    importLimitSelector,
    limitsFetchedSelector,
    loggedInSelector,
} from 'selectors/userSelectors';

import { paramsSelector } from 'selectors/paramsSelectors';

import ActionTypes from 'constants/ActionTypes';
import KeywordsByDomainTypes from 'constants/KeywordsByDomainTypes';
import DataSourceTypes from 'constants/DataSourceTypes';
import DataSubSourceTypes from 'constants/DataSubSourceTypes';
import DeleteResourceTypes from 'constants/DeleteResourceTypes';
import KeywordGraphTypes from 'constants/KeywordGraphTypes';
import KeywordSourceTypes from 'constants/KeywordSourceTypes';
import RoutePaths from 'constants/RoutePaths';
import Strings from 'constants/Strings';
import { analyticsActions, analyticsEvents } from 'constants/analytics';
import { fork } from '@redux-saga/core/effects';
import KdHistorySource from 'sources/KdHistorySource';
import UrlKdDataSource from 'sources/UrlKdDataSource';
import Alert from 'react-s-alert';
import { urlDataLpsSelector } from 'selectors/urlDataSelectors';
import { ErrorTypes } from 'constants/ErrorTypes';
import ContentTypeSource from 'sources/ContentTypeSource';
import SortingColumns from '../constants/SortingColumns';
import SortingTypes from '../constants/SortingTypes';
import SortingStorageKeys from '../constants/SortingStorageKeys';
import SearchIntentSource from 'sources/SearchIntentSource';

const EXPORT_PREFIX = 'kwfinder_';
const EXPORT_SUFFIX = '_export';
const EMPTY_IMPORT_ERROR = {
    status: 200,
    text: 'No keywords returned from import endpoint.',
};

const getPreferredSorting = () => {
    let sortingBy = localStorage.getItem(SortingStorageKeys.COLUMN);
    if (!Object.values(SortingColumns).includes(sortingBy)) {
        sortingBy = SortingColumns.RELEVANCY;
    }
    let sortingDirection = localStorage.getItem(SortingStorageKeys.DIRECTION);
    if (!Object.values(SortingTypes).includes(sortingDirection)) {
        sortingDirection = SortingTypes.DESC;
    }
    return {
        sortingBy,
        sortingDirection,
    };
};

function* resetUiStuff() {
    yield put(setFilterActive(false));
    yield put(unselectAllKeywords());
    const { sortingBy, sortingDirection } = getPreferredSorting();
    yield put(setSortingSettings(sortingBy, sortingDirection));
    yield put(setQuickFilterSearch(''));
}

const fetchContentType = handleUncaught(
    function* fetchContentType(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const options = { accessToken, params: action };
        const { CONTENT_TYPE: isColumnVisible } = yield select(keywordColumnsSettingsVisibleSelector);

        if (!isColumnVisible) {
            return;
        }

        yield put(fetchingContentTypesAction());

        const { result, _timeout } = yield race({
            result: call(ContentTypeSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedContentTypesAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorContentTypesAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchContentType, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorContentTypesAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorContentTypesAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorContentTypesAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchContentTypeSage', payload);
                        } else {
                            yield call(fetchContentType, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorContentTypesAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_content_types_error }),
                            );
                            yield call(logError, 'FetchContentTypeSage', payload);
                        } else {
                            yield call(fetchContentType, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorContentTypesAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchContentTypeSage', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorContentTypesAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchSearchIntent = handleUncaught(
    function* fetchSearchIntent(action, retrying = false) {
        try {

            const accessToken = yield select(accessTokenSelector);
            const options = { accessToken, params: action };
            const { SEARCH_INTENT: isColumnVisible } = yield select(keywordColumnsSettingsVisibleSelector);

            if (!isColumnVisible) {
                return;
            }

            yield put(fetchingSearchIntentAction());

            const { result, _timeout } = yield race({
                result: call(SearchIntentSource.getData, options),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedSearchIntentAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying) {
                                yield put(errorSearchIntentAction(payload));
                            } else {
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchSearchIntent, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorSearchIntentAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorSearchIntentAction(payload));
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorSearchIntentAction(payload));

                            if (payload.type === ErrorTypes.RATE_LIMIT) {
                                yield put(receivedSearchIntentAction(payload));
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying) {
                                yield put(errorSearchIntentAction(payload));
                                yield call(logError, 'FetchSearchIntentSaga', payload);
                            } else {
                                yield call(fetchSearchIntent, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying) {
                                yield put(errorSearchIntentAction(payload));
                                yield call(logError, 'FetchSearchIntentSaga', payload);
                            } else {
                                yield call(fetchSearchIntent, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorSearchIntentAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_search_intent_error }));
                yield call(logError, 'FetchSearchIntentSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } catch (error) {
            yield put(errorSearchIntentAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_search_intent_error }));
        }
    },
    function* onError() {
        yield put(errorSearchIntentAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_search_intent_error }));
    },
);

const fetchResults = handleUncaught(
    function* fetchResults(action, retrying = false) {
        yield call(resetUiStuff);
        yield put(fetchingResultsAction());

        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const options = { accessToken, params };

        const { result, _timeout } = yield race({
            result: call(ResultSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(
                    setKeywordSource({
                        id: null,
                        name: null,
                        type: KeywordSourceTypes.RELATED,
                    }),
                );

                yield put(receivedResultsAction(payload));
                yield call(fetchContentType, payload);
                yield call(fetchSearchIntent, payload);
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        // No logged in users, showing fake data, no special message
                        yield put(errorResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.UNPROCESSABLE_ENTITY: {
                        yield put(errorResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
            yield call(logError, 'FetchResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
    },
);

/* eslint-disable max-len */
const fetchSerpResults = handleUncaught(
    function* fetchSerpResults(options = { disableCache: false, retrying: false }, action) {
        yield put(fetchingSerpResultsAction());

        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const requestOptions = { accessToken, disableCache: options.disableCache, page: 0, params };

        const { result, _timeout } = yield race({
            result: call(SerpResultSource.getData, requestOptions),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        // Hide longer loading notification if this was retry
        // does not matter how it ended up.
        if (options.retrying === true) {
            yield put(hideLongerSerpLoadingNotification());
        }

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                if (isNil(payload)) {
                    // No content means no SERP results for this keyword
                    yield put(receivedNoSerpResultsAction());
                } else {
                    yield put(receivedSerpResultsAction(payload));
                    yield put(receivedSingleSearchIntentAction(payload.searchIntent));
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (options.retrying === true) {
                            yield put(errorSerpResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorSerpResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } // else if (payload.type === ErrorTypes.RATE_LIMIT) {}

                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorSerpResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        // No logged in users, showing fake data, no special message
                        yield put(errorSerpResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.REQUEST_TIMEOUT: {
                        if (options.retrying === true) {
                            yield put(errorSerpResultsAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchSerpResultsSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (options.retrying === true) {
                            yield put(errorSerpResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchSerpResultsSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (options.retrying === true) {
                            yield put(errorSerpResultsAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchSerpResultsSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResults, { disableCache: false, retrying: true }, action);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorSerpResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
            yield call(logError, 'FetchSerpResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSerpResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
    },
);
/* eslint-enable max-len */

const bulkFetchSerpResults = handleUncaught(
    function* bulkFetchSerpResults(options = { disableCache: false, retrying: false }) {
        const selectedKeywordIds = yield select(
            filteredAndSortedSelectedKeywordIdsWithRankUpdatedNotInLastTwentyFourHoursSelector,
        );
        const allKeywords = yield select(filteredAndSortedResultsDataSelector);
        const accessToken = yield select(accessTokenSelector);

        let fetchingError = false;

        // NOTE:
        // using plain old `for` because fancy `forEach, maps` etc.
        // are working on functions and I want to `yield` here
        // so it has to be in a context of this generator function.
        for (let i = 0; i < selectedKeywordIds.length; i += 1) {
            const keywordId = selectedKeywordIds[i];

            yield delay(Math.round(Math.random() * 100) + 50);

            const kwObject = find(propEq('id', keywordId))(allKeywords);
            const requestOptions = {
                accessToken,
                disableCache: options.disableCache,
                page: 0,
                params: {
                    query: kwObject.keyword,
                    languageId: kwObject.language.id,
                    locationId: kwObject.location.id,
                },
            };

            const { result, _timeout } = yield race({
                result: call(SerpResultSource.getData, requestOptions),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error && !isNil(payload)) {
                    yield put(
                        receivedKeywordRankAction(keywordId, payload.rank, payload.rankUpdatedAt, payload.contentTypes),
                    );
                    yield put(receivedSingleSearchIntentAction(payload.searchIntent));
                } else {
                    fetchingError = true;
                    yield put(emptyKeywordRankAction(keywordId));
                }
            } else {
                // Timeout
                yield put(emptyKeywordRankAction(keywordId));
                yield put(errorSerpResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
                yield call(logError, 'BulkFetchSerpResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }

        if (fetchingError) {
            yield call(showErrorNotification, 'Error while refreshing Keyword Difficulty!', {
                html: true,
                timeout: 'none',
            });
        } else {
            yield call(showInfoNotification, 'Keyword Difficulty refresh is <strong>Complete</strong>!', {
                html: true,
            });
        }
        yield put(requestedLimitsAction());
    },
    function* onError() {
        yield put(errorSerpResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
    },
);

const fetchCompetitorsByDomainResults = handleUncaught(
    function* fetchCompetitorsByDomainResults(retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const currentDataParams = yield select(competitorsCurrentDataParamsSelector);
        const options = { accessToken, params };

        if (
            currentDataParams.query === params.query &&
            currentDataParams.locationId === params.locationId &&
            currentDataParams.source === DataSourceTypes.MANGOOLS_COMPETITORS
        ) {
            yield put(skippedCompetitorsByDomainResultsAction());
            return;
        }

        yield put(fetchingCompetitorsByDomainResultsAction());

        const { result, _timeout } = yield race({
            result: call(CompetitorsByDomainResultSource.getData, options),
            _timeout: delay(Defaults.USER_CHECK_INTERVAL), // they guarantee 1 minute
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedCompetitorsByDomainResultsAction(payload));

                if (isNil(payload.data)) {
                    yield put(emptyCompetitorsByDomainResultsAction());
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorCompetitorsByDomainResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchCompetitorsByDomainResults, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorCompetitorsByDomainResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorCompetitorsByDomainResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorCompetitorsByDomainResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorCompetitorsByDomainResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchCompetitorsByDomainResultsSaga', payload);
                        } else {
                            yield call(fetchCompetitorsByDomainResults, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorCompetitorsByDomainResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
                            yield call(logError, 'FetchCompetitorsByDomainResultsSaga', payload);
                        } else {
                            yield call(fetchCompetitorsByDomainResults, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorCompetitorsByDomainResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_competitors_results_error }));
            yield call(logError, 'FetchCompetitorsByDomainResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorCompetitorsByDomainResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_competitors_results_error }));
    },
);

const fetchKeywordsByDomainResults = handleUncaught(
    function* fetchKeywordsByDomainResults(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const options = { accessToken, params };
        const subSource = action.payload;
        const currentDataParams = yield select(currentDataParamsSelector);

        // Check if data are already fetched (organic/paid switch)
        if (
            currentDataParams.query === params.query &&
            currentDataParams.locationId === params.locationId &&
            currentDataParams.source === DataSourceTypes.MANGOOLS_COMPETITORS
        ) {
            yield put(skippedKeywordsByDomainResultsAction());
            return;
        }

        yield call(resetUiStuff);
        yield put(fetchingKeywordsByDomainResultsAction());
        yield put(fetchingSerpResultsAction());

        const { result, _timeout } = yield race({
            result: call(KeywordsByDomainResultSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(
                    setKeywordSource({
                        id: null,
                        name: null,
                        type: KeywordSourceTypes.COMPETITOR,
                    }),
                );

                yield put(receivedKeywordsByDomainResultsAction(payload));
                yield call(fetchContentType, payload.data);
                yield call(fetchSearchIntent, payload.data);

                let { keywords } = payload.data;

                // Get first keyword and set params and defaults
                if (subSource === DataSubSourceTypes.COMPETITORS_ORGANIC) {
                    keywords = keywords.filter(item => item.keywordType === KeywordsByDomainTypes.ORGANIC);
                } else if (subSource === DataSubSourceTypes.COMPETITORS_PAID) {
                    keywords = keywords.filter(item => item.keywordType === KeywordsByDomainTypes.PAID);
                }

                // NOTE: Setting location from params, as in ANYWHERE
                // there might be first keyword from other location.
                const { location } = payload.params;
                yield put(setDefaultKeywordsByDomainLocation(location));

                const kw = keywords[0];

                if (!isNil(kw)) {
                    // request competitors data fetch
                    yield fork(fetchCompetitorsByDomainResults, true);

                    yield put(setCurrentKeywordId(kw.id));
                    yield put(setParams({ query: kw.keyword, languageId: kw.language.id, locationId: kw.location.id }));
                    yield put(setDefaultKeywordsByDomainQuery(payload.params.query));

                    // Request serp data fetch
                    yield call(fetchSerpResults, { disableCache: false, retrying: false }, null);
                } else {
                    yield put(emptyKeywordsByDomainResultsAction());
                    yield call(fetchCompetitorsByDomainResults, true);
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorKeywordsByDomainResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchKeywordsByDomainResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.UNPROCESSABLE_ENTITY: {
                        yield put(errorKeywordsByDomainResultsAction(payload));
                        yield put(
                            showFailureMessage({
                                header: Strings.headers.failure.fetch_results_error_kw_by_domain_422,
                                details: Strings.messages.failure.fetch_results_error_kw_by_domain_422,
                            }),
                        );
                        yield call(logError, 'FetchKeywordsByDomainResultsSaga', payload);
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorKeywordsByDomainResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorKeywordsByDomainResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorKeywordsByDomainResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorKeywordsByDomainResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchKeywordsByDomainResultsSaga', payload);
                        } else {
                            yield call(fetchKeywordsByDomainResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorKeywordsByDomainResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
                            yield call(logError, 'FetchKeywordsByDomainResultsSaga', payload);
                        } else {
                            yield call(fetchKeywordsByDomainResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorKeywordsByDomainResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_keywords_by_domain_results_error }));
            yield call(logError, 'FetchKeywordsByDomainResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorKeywordsByDomainResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_keywords_by_domain_results_error }));
    },
);

const fetchSerpResultsMore = handleUncaught(
    function* fetchSerpResultsMore(action, retrying = false) {
        yield put(fetchingSerpResultsMoreAction());

        const accessToken = yield select(accessTokenSelector);
        const params = yield select(paramsSelector);
        const page = yield select(nextSerpPageSelector);
        const options = { accessToken, page, params };

        yield put(
            gtmTrack({
                action: analyticsActions.CLICK,
                event: analyticsEvents.LOAD_NEXT_SERP,
                pageNumber: page,
            }),
        );

        const { result, _timeout } = yield race({
            result: call(SerpResultSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        // Hide longer loading notification if this was retry
        // does not matter how it ended up.
        if (retrying === true) {
            yield put(hideLongerSerpLoadingNotification());
        }

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                if (isNil(payload)) {
                    // No content means no SERP results for this keyword
                    yield put(receivedNoMoreSerpResultsAction());
                } else {
                    yield put(receivedSerpResultsMoreAction(payload));
                    yield put(receivedSingleSearchIntentAction(payload.searchIntent));
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorSerpResultsMoreAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResultsMore, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorSerpResultsMoreAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } // else if (payload.type === ErrorTypes.RATE_LIMIT) { }

                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorSerpResultsMoreAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.REQUEST_TIMEOUT: {
                        if (retrying === true) {
                            yield put(errorSerpResultsMoreAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchSerpResultsMoreSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResultsMore, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorSerpResultsMoreAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchSerpResultsMoreSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResultsMore, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorSerpResultsMoreAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }),
                            );
                            yield call(logError, 'FetchSerpResultsMoreSaga', payload);
                        } else {
                            yield put(showLongerSerpLoadingNotification());
                            yield call(fetchSerpResultsMore, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorSerpResultsMoreAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
            yield call(logError, 'FetchSerpResultsMoreSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSerpResultsMoreAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_serp_results_error }));
    },
);

const fetchLocations = handleUncaught(
    function* fetchLocations(action, retrying = false) {
        yield put(fetchingLocationsAction());
        const accessToken = yield select(accessTokenSelector);
        const query = action.payload;
        const options = { accessToken, query };

        const { result, _timeout } = yield race({
            result: call(LocationSource.getData, options),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedLocationsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorLocationsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorLocationsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorLocationsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
            yield call(logError, 'FetchLocationsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorLocationsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
    },
);

const fetchTrends = handleUncaught(
    function* fetchTrends(action, retrying = false) {
        const currentKeywordGraphType = yield select(defaultSharedCurrentKeywordGraphSelector);

        if (currentKeywordGraphType === KeywordGraphTypes.TRENDS) {
            const accessToken = yield select(accessTokenSelector);
            const keywordObj = yield select(currentKeywordSelector);

            if (!isNil(keywordObj)) {
                const { keyword, location } = keywordObj;
                const meta = yield select(trendMetaDataSelector);

                if (meta.keyword !== keyword || meta.locationId !== location.id) {
                    yield put(fetchingTrendsAction());

                    const { result, _timeout } = yield race({
                        result: call(TrendSource.getData, {
                            accessToken,
                            keyword,
                            locationId: location.id,
                        }),
                        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
                    });

                    if (!isNil(result)) {
                        const { error, payload } = result;

                        if (!error) {
                            yield put(
                                receivedTrendsAction({ data: payload, meta: { keyword, locationId: location.id } }),
                            );
                        } else {
                            switch (payload.status) {
                                case ErrorCodes.FETCH_ERROR: {
                                    if (retrying === true) {
                                        yield put(errorTrendsAction(payload));
                                        yield put(showNoConnectionMessage());
                                    } else {
                                        // Wait for CONNECTION_RETRY_DELAY and try again
                                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                        yield call(fetchTrends, action, true);
                                    }
                                    break;
                                }
                                case ErrorCodes.ACCESS_DENIED: {
                                    yield put(errorTrendsAction(payload));
                                    yield put(showAccessDeniedMessage());
                                    break;
                                }
                                case ErrorCodes.FAILED_DEPENDENCY: {
                                    // NOTE: Proxy captcha
                                    yield put(errorTrendsAction(payload));
                                    break;
                                }
                                case ErrorCodes.TOO_MANY_REQUESTS: {
                                    yield put(errorTrendsAction(payload));

                                    if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                        yield put(
                                            showFailureMessage({
                                                details: Strings.messages.failure.too_many_requests_error,
                                            }),
                                        );
                                    }

                                    break;
                                }
                                case ErrorCodes.SERVICE_UNAVAILABLE: {
                                    if (retrying === true) {
                                        yield put(errorTrendsAction(payload));
                                        yield put(
                                            showFailureMessage({ details: Strings.messages.failure.maintenance }),
                                        );
                                        yield call(logError, 'FetchTrendsData', payload);
                                    } else {
                                        yield call(fetchTrends, action, true);
                                    }
                                    break;
                                }
                                case ErrorCodes.INTERNAL_SERVER_ERROR:
                                default: {
                                    if (retrying === true) {
                                        yield put(errorTrendsAction(payload));
                                        yield put(
                                            showFailureMessage({
                                                details: Strings.messages.failure.fetch_trends_error,
                                            }),
                                        );
                                        yield call(logError, 'FetchTrendsData', payload);
                                    } else {
                                        yield call(fetchTrends, action, true);
                                    }
                                    break;
                                }
                            }
                        }
                    } else {
                        // Timeout
                        yield put(errorTrendsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
                        yield call(logError, 'FetchTrendsData', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
                    }
                } else {
                    // Already cached
                }
            }
        }
    },
    function* onError() {
        yield put(errorTrendsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
    },
);

const fetchHistoryData = handleUncaught(
    function* fetchHistoryData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingHistoryAction());

            const { result, _timeout } = yield race({
                result: call(HistorySource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedHistoryAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorHistoryAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorHistoryAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_history_error }),
                                );
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
                yield call(logError, 'FetchHistoryDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedHistoryAction());
        }
    },
    function* onError() {
        yield put(errorHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
    },
);

const deleteHistoryData = handleUncaught(function* deleteHistoryData(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);

    const { result, _timeout } = yield race({
        result: call(HistorySource.delete, { accessToken }),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(accessToken)) {
        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedDeleteHistoryAction());

                yield call(showInfoNotification, 'History was successfully cleared.', { html: false });
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorDeleteHistoryAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorDeleteHistoryAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.delete_history }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorDeleteHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'DeleteHistorySaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    }
});

const fetchKdHistoryData = handleUncaught(
    function* fetchKdHistoryData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingKdHistoryAction());

            const { result, _timeout } = yield race({
                result: call(KdHistorySource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedKdHistoryAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorKdHistoryAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchKdHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorKdHistoryAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorKdHistoryAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorKdHistoryAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchKdHistoryDataSaga', payload);
                            } else {
                                yield call(fetchKdHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorKdHistoryAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_history_error }),
                                );
                                yield call(logError, 'FetchKdHistoryDataSaga', payload);
                            } else {
                                yield call(fetchKdHistoryData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorKdHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
                yield call(logError, 'FetchKdHistoryDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedKdHistoryAction());
        }
    },
    function* onError() {
        yield put(errorKdHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
    },
);

const deleteKdHistoryData = handleUncaught(function* deleteKdHistoryData(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);

    const { result, _timeout } = yield race({
        result: call(KdHistorySource.delete, { accessToken }),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(accessToken)) {
        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedDeleteKdHistoryAction());

                yield call(showInfoNotification, 'History was successfully cleared.', { html: false });
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorDeleteKdHistoryAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorDeleteKdHistoryAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorDeleteKdHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'DeleteKdHistorySaga', payload);
                        } else {
                            yield call(deleteKdHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorDeleteKdHistoryAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorDeleteKdHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.delete_history }));
                            yield call(logError, 'DeleteKdHistorySaga', payload);
                        } else {
                            yield call(deleteKdHistoryData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorDeleteKdHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'DeleteKdHistorySaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    }
});

const fetchUrlData = handleUncaught(
    function* fetchUrlData(action, retrying = false) {
        yield put(fetchingUrlDataAction());
        const accessToken = yield select(accessTokenSelector);
        const url = yield select(comparingBoxUrlSelector);
        const protocol = yield select(comparingBoxUrlProtocolSelector);
        const urlWithProtocol = `${protocol.raw}${url}`;

        const { result, _timeout } = yield race({
            result: call(UrlKdDataSource.getData, { accessToken }, { url: urlWithProtocol }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                if (payload?.linkProfileStrength === 'N/A') {
                    Alert.error('We haven’t found any data for your URL.');
                }
                // open dropdown
                yield put(showRelativeKdDropdown());
                yield put(receivedUrlDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorUrlDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorUrlDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorUrlDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorUrlDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
            yield call(logError, 'FetchUrlDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorUrlDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

const fetchListData = handleUncaught(
    function* fetchListData(retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingListsAction());

            const { result, _timeout } = yield race({
                result: call(ListSource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchListData, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchListsDataSaga', payload);
                            } else {
                                yield call(fetchListData, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_lists_error }));
                                yield call(logError, 'FetchListsDataSaga', payload);
                            } else {
                                yield call(fetchListData, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchListsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedListsAction());
        }
    },
    function* onError() {
        yield put(errorListsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const deleteList = handleUncaught(
    function* deleteList(action, retrying = false) {
        const { id, name } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(showDeleteConfirmationMessage({ resourceName: name, resourceType: DeleteResourceTypes.LIST }));

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingListsDeleteAction());

            const { result, _timeout } = yield race({
                result: call(ListSource.delete, accessToken, id),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const kwSourceType = yield select(keywordSourceResourceTypeSelector);

                    if (kwSourceType === KeywordSourceTypes.LIST) {
                        const kwSourceResourceId = yield select(keywordSourceResourceIdSelector);

                        if (kwSourceResourceId === id) {
                            // Deleting currently opened list, redirect to root
                            yield put(requestedNavigationAction(RoutePaths.ROOT, {}));
                        }
                    }

                    yield put(receivedListsDeleteAction(id));
                    yield call(showInfoNotification, `List <strong>${name}</strong> was successfully deleted.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsDeleteAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsDeleteAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsDeleteAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsDeleteAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteListSaga', payload);
                            } else {
                                yield call(deleteList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsDeleteAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.delete_list_error }));
                                yield call(logError, 'DeleteListSaga', payload);
                            } else {
                                yield call(deleteList, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsDeleteAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_list_error }));
                yield call(logError, 'DeleteListSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledListsDeleteAction(id));
        }
    },
    function* onError() {
        yield put(errorListsDeleteAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_list_error }));
    },
);

const renameList = handleUncaught(
    function* renameList(action, retrying = false) {
        const { id, name } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingListsRenameAction());

            const { result, _timeout } = yield race({
                result: call(ListSource.rename, accessToken, name, id),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsRenameAction(id, name));
                    yield call(
                        showInfoNotification,
                        ReactDOMServer.renderToStaticMarkup(<ListUpdatedAlert listName={name}/>),
                        {
                            html: true,
                        },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsRenameAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(renameList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsRenameAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsRenameAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsRenameAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'RenameListSaga', payload);
                            } else {
                                yield call(renameList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsRenameAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.rename_list_error }));
                                yield call(logError, 'RenameListSaga', payload);
                            } else {
                                yield call(renameList, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsRenameAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.rename_list_error }));
                yield call(logError, 'RenameListSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorListsRenameAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.rename_list_error }));
    },
);

const exportSelectedKeywordsData = handleUncaught(
    function* exportSelectedKeywordsData(action) {
        yield put(setExporting(true));

        const sortedKeywords = yield select(filteredAndSortedSelectedKeywordsSelector);
        const kwSourceType = yield select(keywordSourceResourceTypeSelector);
        const stringForFilename = yield select(exportSelectedKeywordsFilenameSelector);
        const currency = yield select(currencySelector);
        const urlDataLps = yield select(urlDataLpsSelector);
        const relativeKdActive = yield select(relativeKdActiveSelector);

        const filename = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(stringForFilename)}${EXPORT_SUFFIX}`;

        const csv = yield call(ExportService.exportKeywords, sortedKeywords, {
            kwSourceType,
            currency,
            urlDataLps,
            relativeKdActive,
        });

        const success = yield call(DownloaderService.downloadCSV, filename, csv);

        if (success) {
            yield call(showInfoNotification, 'Your keywords were successfully exported.');
            yield put(setExporting(false));
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield call(logError, 'ExportSelectedKeywordsDataSaga|DownloaderService', action.payload);
            yield put(setExporting(false));
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
        yield put(setExporting(false));
    },
);

function* revertUpdateListsAddKeywordsSaga(list) {
    yield put(
        revertUpdateListsAddKeywords({
            listId: list.id,
            keywordIds: list.keywordIds,
            updatedAt: list.updatedAt,
        }),
    );
}

const addOrCreateList = handleUncaught(
    function* addOrCreateList(action, retrying = false, prevList = null) {
        const { isNew, nameOrId } = action.payload;
        const keywordIds = yield select(addToListMessageAddingKeywordIdsSelector);
        const accessToken = yield select(accessTokenSelector);

        if (isNew === true) {
            yield put(fetchingListsAction());

            const { result, _timeout } = yield race({
                result: call(ListSource.create, accessToken, keywordIds, nameOrId),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsNewAction(payload));
                    yield call(
                        showInfoNotification,
                        ReactDOMServer.renderToStaticMarkup(<ListCreatedAlert listName={nameOrId}/>),
                        {
                            html: true,
                        },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsCreateAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(addOrCreateList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsCreateAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsCreateAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsCreateAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'AddOrCreateListDataSaga', payload);
                            } else {
                                yield call(addOrCreateList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsCreateAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.create_list_error }));
                                yield call(logError, 'AddOrCreateListDataSaga', payload);
                            } else {
                                yield call(addOrCreateList, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsCreateAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.create_list_error }));
                yield call(logError, 'AddOrCreateListDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else if (isNew === false) {
            yield put(fetchingListsAction());
            const currentList = yield select(listSelector, nameOrId);

            if (retrying === false) {
                yield put(updateListsAddKeywords({ listId: nameOrId, keywordIds }));

                const keywordCount = keywordIds.length;
                const label = keywordCount > 1 ? 'keywords were' : 'keyword was';

                yield call(
                    showInfoNotification,
                    `${keywordCount} ${label} successfully added to <strong>${currentList.name}</strong>.`,
                    { html: true },
                );
            }

            const { result, _timeout } = yield race({
                result: call(ListSource.addKeywords, { accessToken, keywordIds, listId: nameOrId }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsAddKeywordsAction());
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield call(revertUpdateListsAddKeywordsSaga, prevList);
                                yield put(errorListsAddKeywordsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(addOrCreateList, action, true, currentList);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield call(revertUpdateListsAddKeywordsSaga, currentList);
                            yield put(errorListsAddKeywordsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield call(revertUpdateListsAddKeywordsSaga, currentList);
                            yield put(errorListsAddKeywordsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.UNPROCESSABLE_ENTITY: {
                            yield call(revertUpdateListsAddKeywordsSaga, currentList);
                            yield put(errorListsAddKeywordsAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.update_list_limit_error }),
                            );
                            yield call(logError, 'AddOrCreateListDataSaga', payload);
                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield call(revertUpdateListsAddKeywordsSaga, prevList);
                                yield put(errorListsAddKeywordsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'AddOrCreateListDataSaga', payload);
                            } else {
                                yield call(addOrCreateList, action, true, currentList);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield call(revertUpdateListsAddKeywordsSaga, prevList);
                                yield put(errorListsAddKeywordsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.update_list_error }));
                                yield call(logError, 'AddOrCreateListDataSaga', payload);
                            } else {
                                yield call(addOrCreateList, action, true, currentList);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield call(revertUpdateListsAddKeywordsSaga, currentList);
                yield put(errorListsAddKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.update_list_error }));
                yield call(logError, 'AddOrCreateListDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorListsAddKeywordsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.update_list_error }));
    },
);

const loadDashboardWithListData = handleUncaught(
    function* loadDashboardWithListData(action, retrying = false) {
        const listId = action.payload;

        yield call(resetUiStuff);
        yield put(fetchingListKeywordsAction());

        const accessToken = yield select(accessTokenSelector);

        const { result, _timeout } = yield race({
            result: call(ListSource.getDetail, accessToken, listId),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                const { list, keywords } = payload;

                // Set list as kw source and also the name of the list
                yield put(
                    setKeywordSource({
                        id: list.id,
                        name: list.name,
                        type: KeywordSourceTypes.LIST,
                    }),
                );

                // Return list keywords
                yield put(receivedListKeywordsAction(keywords));

                // Get first keyword and set params
                const kw = keywords[0];
                yield put(setParams({ query: kw.keyword, languageId: kw.language.id, locationId: kw.location.id }));

                // Request serp data fetch
                yield call(fetchSerpResults, { disableCache: false, retrying: false }, null);

                // Request search intent data
                yield call(fetchSearchIntent, payload);

                // Request content type data
                yield call(fetchContentType, payload);
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorListKeywordsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(loadDashboardWithListData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorListKeywordsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNPROCESSABLE_ENTITY: {
                        yield put(errorListKeywordsAction(payload));
                        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_list_detail_error }));
                        yield call(logError, 'LoadDashboardWithListsDataSaga', payload);
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorListKeywordsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorListKeywordsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'LoadDashboardWithListsDataSaga', payload);
                        } else {
                            yield call(loadDashboardWithListData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorListKeywordsAction(payload));
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.fetch_list_detail_error }),
                            );
                            yield call(logError, 'LoadDashboardWithListsDataSaga', payload);
                        } else {
                            yield call(loadDashboardWithListData, action, true);
                        }
                        break;
                    }
                }

                yield call(logError, 'LoadDashboardWithListsDataSaga', payload);
            }
        } else {
            yield put(errorListKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_list_detail_error }));
            yield call(logError, 'LoadDashboardWithListsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorListKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_list_detail_error }));
    },
);

const importCurrentKeywords = handleUncaught(function* importCurrentKeywords() {
    const keywordData = yield select(resultsDataSelector);
    const keywords = keywordData.map(kw => kw.keyword);

    const location = yield select(defaultGoogleLocationSelector);
    const locationId = location.id;

    yield put(requestedKeywordsImportAction(keywords, locationId));
});

const loadDashboardWithImportedData = handleUncaught(
    function* loadDashboardWithImportedData(action, retrying = false) {
        const { keywords, locationId } = action.payload;
        yield put(requestedNavigationAction(RoutePaths.DASHBOARD, { importId: Date.now() }));

        yield call(resetUiStuff);
        yield put(fetchingImportAction());

        const accessToken = yield select(accessTokenSelector);

        const { result, _timeout } = yield race({
            result: call(ImportSource.getData, accessToken, { keywords, locationId }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;
            const { data, meta } = payload;

            if (!error) {
                if (!isEmpty(data)) {
                    // Set import as kw source
                    yield put(
                        setKeywordSource({
                            id: null,
                            name: null,
                            type: KeywordSourceTypes.IMPORT,
                        }),
                    );

                    // Return received keywords
                    yield put(receivedImportAction({ data, meta }));

                    // Get first keyword and set params
                    const kw = data[0];
                    yield put(setParams({ query: kw.keyword, languageId: kw.language.id, locationId: kw.location.id }));
                    yield put(setDefaultGoogleLocation(kw.location));

                    // Request serp data fetch
                    yield call(fetchSerpResults, { disableCache: false, retrying: false }, null);

                    // Request search intent data
                    yield call(fetchSearchIntent, { keywords: payload.data });

                    // Request content type data
                    yield call(fetchContentType, { keywords: payload.data });
                } else {
                    // No keywords were returned from import, show import error
                    yield put(errorImportAction(EMPTY_IMPORT_ERROR));
                    yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
                    yield call(logError, 'LoadDashboardWithImportedDataSaga', EMPTY_IMPORT_ERROR);
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(loadDashboardWithImportedData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.FAILED_DEPENDENCY: {
                        yield put(errorImportAction({ ...payload, lastPayload: action.payload }));
                        yield put(
                            showFailureMessage({
                                details: Strings.messages.failure.import_error_424,
                            }),
                        );
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorImportAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorImportAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'LoadDashboardWithImportedDataSaga', payload);
                        } else {
                            yield call(loadDashboardWithImportedData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
                            yield call(logError, 'LoadDashboardWithImportedDataSaga', payload);
                        } else {
                            yield call(loadDashboardWithImportedData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorImportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
            yield call(logError, 'LoadDashboardWithImportedDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorImportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
    },
);

const loadDashboardWithImportedAutocompleteData = handleUncaught(
    function* loadDashboardWithImportedAutocompleteData(
        { keywords, languageId, locationId, subSourceType },
        retrying = false,
    ) {
        yield call(resetUiStuff);
        yield put(fetchingImportAction());

        const accessToken = yield select(accessTokenSelector);

        const { result, _timeout } = yield race({
            result: call(ImportSource.getData, accessToken, { keywords, languageId, locationId }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;
            const { data, meta } = payload;

            if (!error) {
                if (!isEmpty(data)) {
                    // Setting autocomplete/questions as kw source type
                    if (subSourceType === DataSubSourceTypes.GOOGLE_AUTOCOMPLETE) {
                        yield put(
                            setKeywordSource({
                                id: null,
                                name: null,
                                type: KeywordSourceTypes.AUTOCOMPLETE,
                            }),
                        );
                    } else if (subSourceType === DataSubSourceTypes.GOOGLE_QUESTIONS) {
                        yield put(
                            setKeywordSource({
                                id: null,
                                name: null,
                                type: KeywordSourceTypes.QUESTIONS,
                            }),
                        );
                    }

                    // Return received keywords
                    yield put(finishedGoogleResultsAction());
                    yield put(receivedImportAction({ data, meta }));

                    // Get first keyword and set params and defaults
                    const kw = data[0];
                    yield put(setParams({ query: kw.keyword, languageId: kw.language.id, locationId: kw.location.id }));
                    yield put(setDefaultGoogleQuery(kw.keyword));
                    yield put(setDefaultGoogleLanguage(kw.language));
                    yield put(setDefaultGoogleLocation(kw.location));

                    // Request serp data fetch
                    yield call(fetchSerpResults, { disableCache: false, retrying: false }, null);

                    // Request search intent data
                    yield call(fetchSearchIntent, { keywords: payload.data });

                    // Request content type data
                    yield call(fetchContentType, { keywords: payload.data });
                } else {
                    // No keywords were returned from import, show import error
                    yield put(errorImportAction(EMPTY_IMPORT_ERROR));
                    yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
                    yield call(logError, 'LoadDashboardWithImportedAutocompleteDataSaga', EMPTY_IMPORT_ERROR);
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(
                                loadDashboardWithImportedAutocompleteData,
                                {
                                    keywords,
                                    languageId,
                                    locationId,
                                    subSourceType,
                                },
                                true,
                            );
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorImportAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.FAILED_DEPENDENCY: {
                        yield put(errorImportAction(payload));
                        yield put(
                            showFailureMessage({
                                details: Strings.messages.failure.import_error_424,
                            }),
                        );
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorImportAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'LoadDashboardWithImportedAutocompleteDataSaga', payload);
                        } else {
                            yield call(
                                loadDashboardWithImportedAutocompleteData,
                                {
                                    keywords,
                                    languageId,
                                    locationId,
                                    subSourceType,
                                },
                                true,
                            );
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorImportAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
                            yield call(logError, 'LoadDashboardWithImportedAutocompleteDataSaga', payload);
                        } else {
                            yield call(
                                loadDashboardWithImportedAutocompleteData,
                                {
                                    keywords,
                                    languageId,
                                    locationId,
                                    subSourceType,
                                },
                                true,
                            );
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorImportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
            yield call(logError, 'LoadDashboardWithImportedAutocompleteDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorImportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.import_error }));
    },
);

// NOTE: Not retryable
function* fetchAggregatedGoogleResults(subSourceType, { keyword, limit, languageCode, locationCode }) {
    const result = { error: false, payload: [] };
    let generatedKeywords = [];

    if (subSourceType === DataSubSourceTypes.GOOGLE_AUTOCOMPLETE) {
        generatedKeywords = yield call(generateKeywords, keyword, languageCode);
    } else if (subSourceType === DataSubSourceTypes.GOOGLE_QUESTIONS) {
        generatedKeywords = yield call(generateQuestions, keyword, languageCode);
    }

    const requestCount = generatedKeywords.length;
    yield put(setDashboardLeftLoaderProgress(requestCount, 0));

    // NOTE:
    // using plain old `for` because fancy `forEach, maps` etc.
    // are working on functions and I want to `yield` here
    // so it has to be in a context of this generator function.
    for (let i = 0; i < generatedKeywords.length; i += 1) {
        const nextKeyword = generatedKeywords[i];
        if (result.payload.length < limit) {
            // Wait a random time between 50 and 150ms for
            // less suspicious requests to google search API
            yield delay(Math.round(Math.random() * 100) + 50);

            // Get data for next keyword
            const { error, payload } = yield call(GoogleSource.getData, {
                keyword: nextKeyword,
                languageCode,
                locationCode,
            });

            if (error === false) {
                // Filter URLs, concat, uniq returned autocomplete keywords
                const filteredPayload = payload.filter(kw => !isURL(kw));
                result.payload = concat(result.payload, filteredPayload);
                result.payload = uniq(result.payload);

                // Update finished state for loading indicator
                yield put(setDashboardLeftLoaderProgress(requestCount, i + 1));
            } else {
                result.error = true;
                result.payload = payload;
                break;
            }
        }
    }

    if (result.error === false) {
        // This should be set in last iteration, but when we hit `limit`
        // we dont have to do more requests. So jump to the end.
        yield put(setDashboardLeftLoaderProgress(requestCount, requestCount));

        if (result.payload.length > 0) {
            // We have at least one autocompleted keyword
            // so add seed keyword at the top and return
            result.payload = concat([keyword], result.payload);
        }

        // Slice according to limits
        result.payload = slice(0, limit, result.payload);

        return result;
    } else {
        // Return error payload
        return result;
    }
}

// NOTE: Not retryable
const fetchGoogleResults = handleUncaught(
    function* fetchGoogleResults(subSourceType) {
        yield put(fetchingGoogleResultsAction());
        const limitsFetched = yield select(limitsFetchedSelector);
        const loggedIn = yield select(loggedInSelector);

        if (loggedIn === true && limitsFetched === false) {
            // Wait for import limit to be determined
            yield take(ActionTypes.DATA_USER_LIMIT_DATA_RECEIVED);
        }

        const language = yield select(defaultGoogleLanguageSelector);
        const limit = yield select(importLimitSelector);
        const params = yield select(paramsSelector);

        // NOTE: When using location select, defaultLocation will be the correct location.
        // When navigating via link/form then the locationId from params will differ
        // from defaultLocation. So we fetch this new location and save it as default.
        const defaultLocation = yield select(defaultGoogleLocationSelector);
        let location = defaultLocation;

        if (defaultLocation.id !== params.locationId) {
            const accessToken = yield select(accessTokenSelector);

            const { result, _timeout } = yield race({
                result: call(LocationSource.getById, { accessToken, id: params.locationId }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    location = payload;
                    yield put(setDefaultGoogleLocation(payload));
                }
            }
        }

        // GoogleSource options
        const options = {
            keyword: params.query,
            languageCode: language.code,
            limit,
            locationCode: location.code,
        };

        const { result, timeout } = yield race({
            result: call(fetchAggregatedGoogleResults, subSourceType, options),
            timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 3),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (error === true) {
                yield put(errorGoogleResultsAction(payload));

                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        yield put(showNoConnectionMessage());
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        yield put(
                            showFailureMessage({
                                header: Strings.headers.failure.fetch_google_results_error,
                                details: Strings.messages.failure.fetch_google_results_error,
                            }),
                        );
                        break;
                    }
                }

                yield call(logError, 'FetchGoogleResultsSaga', payload);
            } else if (error === false && !isEmpty(payload)) {
                yield call(
                    loadDashboardWithImportedAutocompleteData,
                    {
                        keywords: payload,
                        languageId: language.id,
                        locationId: location.id,
                        subSourceType,
                    },
                    false,
                );
            } else if (isEmpty(payload)) {
                yield put(emptyGoogleResultsAction());
            }
        } else if (!isNil(timeout)) {
            // Timeout
            yield put(errorGoogleResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_google_results_timeout_error }));
            yield call(logError, 'FetchGoogleResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorGoogleResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_google_results_error }));
    },
);

function* fetchGoogleAutocompleteResults() {
    yield call(fetchGoogleResults, DataSubSourceTypes.GOOGLE_AUTOCOMPLETE);
}

function* fetchGoogleQuestionsResults() {
    yield call(fetchGoogleResults, DataSubSourceTypes.GOOGLE_QUESTIONS);
}

const deleteKeywordFromList = handleUncaught(
    function* deleteKeywordFromList(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const { keywordId, listId } = action.payload;
        const currentListId = yield select(keywordSourceResourceIdSelector);

        const isCurrentList = !isNil(currentListId) && currentListId === listId;

        if (!retrying) {
            if (!isCurrentList) {
                yield put(optimisticDeleteKeywordFromListAction({ listId, keywordId }));
            } else {
                yield put(optimisticDeleteKeywordsFromListAction({ listId, keywordIds: [keywordId] }));
            }

            yield call(showInfoNotification, 'Keyword was successfully removed from list', { html: false });
        }

        const { result, _timeout } = yield race({
            result: call(ListSource.deleteKeywords, { accessToken, keywordIds: [keywordId], listId }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
        });

        if (!isNil(accessToken)) {
            if (!isNil(result)) {
                const { error, payload } = result;

                if (error) {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(showNoConnectionMessage());
                                if (!isCurrentList) {
                                    yield put(revertOptimisticDeleteKeywordFromListAction());
                                } else {
                                    yield put(revertOptimisticDeleteKeywordsFromListAction());
                                }
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteKeywordFromList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(showAccessDeniedMessage());
                            if (!isCurrentList) {
                                yield put(revertOptimisticDeleteKeywordFromListAction());
                            } else {
                                yield put(revertOptimisticDeleteKeywordsFromListAction());
                            }
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            if (!isCurrentList) {
                                yield put(revertOptimisticDeleteKeywordFromListAction());
                            } else {
                                yield put(revertOptimisticDeleteKeywordsFromListAction());
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteKeywordFromListSaga', payload);
                                if (!isCurrentList) {
                                    yield put(revertOptimisticDeleteKeywordFromListAction());
                                } else {
                                    yield put(revertOptimisticDeleteKeywordsFromListAction());
                                }
                            } else {
                                yield call(deleteKeywordFromList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.delete_keywords_from_list_error,
                                    }),
                                );
                                if (!isCurrentList) {
                                    yield put(revertOptimisticDeleteKeywordFromListAction());
                                } else {
                                    yield put(revertOptimisticDeleteKeywordsFromListAction());
                                }
                                yield call(logError, 'DeleteKeywordFromListSaga', payload);
                            } else {
                                yield call(deleteKeywordFromList, action, true);
                            }
                        }
                    }
                }
            } else {
                if (!isCurrentList) {
                    yield put(revertOptimisticDeleteKeywordFromListAction());
                } else {
                    yield put(revertOptimisticDeleteKeywordsFromListAction());
                }
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_keywords_from_list_error }));
                yield call(logError, 'DeleteKeywordsFromCurrentListSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(revertOptimisticDeleteKeywordFromListAction());
        yield put(revertOptimisticDeleteKeywordsFromListAction());
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_keyword_from_list_error }));
    },
);
const deleteKeywordsFromCurrentList = handleUncaught(
    function* deleteKeywordsFromCurrentList(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const selectedKeywordIds = yield select(filteredAndSortedSelectedKeywordIdsSelector);
        const newCurrentKwId = yield select(newCurrentKwIdSelector);
        const selectedKeywordCount = selectedKeywordIds.length;
        const list = yield select(currentListDetailDataSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: selectedKeywordCount.toString(),
                    resourceType: DeleteResourceTypes.SELECTED_KEYWORDS,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if (!isNil(confirm) && !retrying) {
            // NOTE: Not deactivating filter, sorting setting, just resetting selections
            yield put(unselectAllKeywords());

            // Handle empty list and deleted current kw
            if (list.keywordIds.length === selectedKeywordIds.length) {
                // Deleted all kws, redirect to root
                yield put(requestedNavigationAction(RoutePaths.ROOT, {}));
            }

            yield put(optimisticDeleteKeywordsFromListAction({ listId: list.id, keywordIds: selectedKeywordIds }));

            // Show notification
            const label = selectedKeywordCount > 1 ? 'keywords were' : 'keyword was';

            yield call(showInfoNotification, `${selectedKeywordCount} ${label} successfully removed`, {
                html: true,
            });

            yield put(setCurrentKeywordId(newCurrentKwId));
        }

        if (!isNil(confirm) || retrying === true) {
            const { result, _timeout } = yield race({
                result: call(ListSource.deleteKeywords, {
                    accessToken,
                    keywordIds: selectedKeywordIds,
                    listId: list.id,
                }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT * 2),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (error) {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(revertOptimisticDeleteKeywordsFromListAction());
                                yield put(errorDeleteSelectedKeywordsFromCurrentListAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteKeywordsFromCurrentList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(revertOptimisticDeleteKeywordsFromListAction());
                            yield put(errorDeleteSelectedKeywordsFromCurrentListAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(revertOptimisticDeleteKeywordsFromListAction());
                            yield put(errorDeleteSelectedKeywordsFromCurrentListAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(revertOptimisticDeleteKeywordsFromListAction());
                                yield put(errorDeleteSelectedKeywordsFromCurrentListAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteKeywordsFromCurrentListSaga', payload);
                            } else {
                                yield call(deleteKeywordsFromCurrentList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(revertOptimisticDeleteKeywordsFromListAction());
                                yield put(errorDeleteSelectedKeywordsFromCurrentListAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.delete_keywords_from_list_error,
                                    }),
                                );
                                yield call(logError, 'DeleteKeywordsFromCurrentListSaga', payload);
                            } else {
                                yield call(deleteKeywordsFromCurrentList, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(revertOptimisticDeleteKeywordsFromListAction());
                yield put(errorDeleteSelectedKeywordsFromCurrentListAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_keywords_from_list_error }));
                yield call(logError, 'DeleteKeywordsFromCurrentListSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledDeleteSelectedKeywordsFromCurrentListAction());
        }
    },
    function* onError() {
        yield put(revertOptimisticDeleteKeywordsFromListAction());
        yield put(errorDeleteSelectedKeywordsFromCurrentListAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_keywords_from_list_error }));
    },
);

const fetchAnnouncements = handleUncaught(
    function* fetchAnnouncements(retrying = false) {
        yield put(fetchingAnnouncementsAction());

        const { result, _timeout } = yield race({
            result: call(AnnouncementsSource.getData),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedAnnouncementsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAnnouncementsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAnnouncementsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_announcements_error,
                                }),
                            );
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorAnnouncementsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchAnnouncementsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAnnouncementsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const checkUnleashSession = handleUncaught(function* checkUnleashSession(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(UnleashSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            yield put(setUnleashSessionAction({ unleashSession: payload.sessionId }));
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchUnleashSessionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

// NOTE:
//
// Updates kw rank from finished SERP request.
//
// If this was after fetching parallel results,
// it will be also set by `updateRankAfterParallelRequests`.
// It would be pretty complicated to cancel/block this action
// in that case, and double set of the same rank value is not
// a big deal. UI won't rerender anyway.
const updateKeywordRank = handleUncaught(function* updateKeywordRank(action) {
    const { rank, rankUpdatedAt, contentTypes } = action.payload;
    const currentKeywordId = yield select(currentKeywordIdSelector);

    if (!isNil(currentKeywordId)) {
        yield put(receivedKeywordRankAction(currentKeywordId, rank, rankUpdatedAt, contentTypes));
    }
});

const updateDefaultLocation = handleUncaught(function* updateDefaultLocation(action) {
    const { params } = action.payload;
    yield put(setDefaultGoogleLocation(params.location));
});

const updateContentTypeFromSerps = handleUncaught(function* updateContentTypeFromSerps(action) {
    const { kwId, contentTypes } = action.payload;
    yield put(
        receivedContentTypesAction({
            [kwId]: contentTypes ?? [],
        }),
    );
});

const updateContentTypeFromRefresh = handleUncaught(function* updateContentTypeFromRefresh(action) {
    const { id, contentTypes } = action.payload;
    yield put(
        receivedContentTypesAction({
            [id]: contentTypes ?? [],
        }),
    );
});

/**
 * NOTE:
 *
 * Updates rank after parallel related/serp results requests.
 * Waits for two races with success/error fetchig actions.
 * This way it covers both cases. When first finishes SERP request
 * and also when first finishes related request.
 */
const updateRankAfterParallelRequests = handleUncaught(function* updateRankAfterParallelRequests() {
    // Wait for both data results received (in no particular order)
    // while racing for either success or error.
    const [serpAction, resultAction] = yield all([
        race({
            success: take(ActionTypes.DATA_SERP_RESULTS_RECEIVED),
            error: take(ActionTypes.DATA_SERP_RESULTS_ERROR),
        }),
        race({
            success: take(ActionTypes.DATA_RESULTS_RECEIVED),
            error: take(ActionTypes.DATA_RESULTS_ERROR),
        }),
    ]);

    // Continue only if both finished with success
    if (!isNil(serpAction.success) && !isNil(resultAction.success)) {
        // Get rank from serp payload and set it for kwId from related payload
        const { rank, rankUpdatedAt, contentTypes } = serpAction.success.payload;
        const firstKw = resultAction.success.payload.data[0];

        if (!isNil(firstKw)) {
            yield put(receivedKeywordRankAction(firstKw.id, rank, rankUpdatedAt, contentTypes));
        }
    }
});

const checkNewAppVersion = handleUncaught(function* checkNewAppVersion(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(VersionSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            let newAppVersion;
            const currentAppVersion = config.APP_VERSION;

            if (config.production()) {
                newAppVersion = payload.kwfinder;
            } else {
                newAppVersion = payload.kwfinderBeta;
            }

            if (!isNil(newAppVersion)) {
                const newAppVersionParts = newAppVersion.split('.');
                const newAppVersionMajor = parseInt(newAppVersionParts[0], 10);
                // console.log('newAppVersionMajor', newAppVersionMajor);
                const newAppVersionMinor = parseInt(newAppVersionParts[1], 10);
                // console.log('newAppVersionMinor', newAppVersionMinor);

                const currentAppVersionParts = currentAppVersion.split('.');
                const currentAppVersionMajor = parseInt(currentAppVersionParts[0], 10);
                // console.log('currentAppVersionMajor', currentAppVersionMajor);
                const currentAppVersionMinor = parseInt(currentAppVersionParts[1], 10);
                // console.log('currentAppVersionMinor', currentAppVersionMinor);

                if (newAppVersionMajor > currentAppVersionMajor || newAppVersionMinor > currentAppVersionMinor) {
                    const notificationShown = yield select(newVersionNotificationShownSelector);

                    // Only show if not already shown during this session
                    if (notificationShown === false) {
                        // We have an older version of application.
                        // Show a special notification.
                        yield call(
                            showInfoNotification,
                            `
                                <h4 class="font-14">
                                    UPDATE AVAILABLE 🤩
                                </h4>

                                <p>
                                    Please, reload the application to get newest features and prevent possible glitches.
                                </p>

                                <br />

                                <button
                                    class="mg-btn is-xsmall is-orange is-gradient mg-margin-t-5"
                                    onclick="location.reload();"
                                    type="button"
                                >
                                    Reload now
                                </button>
                            `,
                            {
                                html: true,
                                timeout: 'none',
                            },
                        );

                        yield put(setNewVersionNotificationShown());
                    }
                }
            }
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchAppVersionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

export const fetchCurrencyRates = handleUncaught(
    function* fetchCurrencyRates(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingCurrencyRatesAction());

            const { result, _timeout } = yield race({
                result: call(CurrencyRatesSource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const selectedCurrency = yield select(currencySelector);
                    yield put(receivedCurrencyRatesAction(payload));
                    yield put(
                        setCurrency(payload.currencies.find(currency => currency.code === selectedCurrency.code)),
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorCurrencyRatesAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchCurrencyRates, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorCurrencyRatesAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorCurrencyRatesAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'fetchCurrencyRatesSaga', payload);
                            } else {
                                yield call(fetchCurrencyRates, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorCurrencyRatesAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_currencies_error }),
                                );
                                yield call(logError, 'fetchCurrencyRatesSaga', payload);
                            } else {
                                yield call(fetchCurrencyRates, action, true);
                            }
                            break;
                        }
                    }
                }
            }
        }
    },
    function* onError() {
        yield put(errorHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_currencies_error }));
    },
);

function* watchResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_REQUESTED, fetchResults);
    yield takeLatest(ActionTypes.DATA_RESULTS_REQUESTED, updateRankAfterParallelRequests);
}

function* watchKeywordsByDomainResultsRequests() {
    yield takeLatest(ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_REQUESTED, fetchKeywordsByDomainResults);
}

function* watchCompetitorsByDomainResultsRequests() {
    yield takeLatest(ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_REQUESTED, fetchCompetitorsByDomainResults);
}

function* watchGoogleAutocompleteResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_GOOGLE_AUTOCOMPLETE_REQUESTED, fetchGoogleAutocompleteResults);
}

function* watchGoogleQuestionsResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_GOOGLE_QUESTIONS_REQUESTED, fetchGoogleQuestionsResults);
}

function* watchSerpResultsRequests() {
    yield takeLatest(ActionTypes.DATA_SERP_RESULTS_REQUESTED, fetchSerpResults, {
        disableCache: false,
        retrying: false,
    });
}

function* watchUncachedSerpResultsRequests() {
    yield takeLatest(ActionTypes.DATA_SERP_RESULTS_UNCACHED_REQUESTED, fetchSerpResults, {
        disableCache: true,
        retrying: false,
    });
}

function* watchSerpResultsMoreRequests() {
    yield takeLatest(ActionTypes.DATA_SERP_RESULTS_MORE_REQUESTED, fetchSerpResultsMore);
}

function* watchLocationsRequests() {
    yield takeLatest(ActionTypes.DATA_LOCATIONS_REQUESTED, fetchLocations);
}

// NOTE: All these requests are updating the current keyword which might need
// to fetch trends data.
function* watchTrendsRequests() {
    yield takeLatest(ActionTypes.DATA_IMPORT_RECEIVED, fetchTrends);
    yield takeLatest(ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED, fetchTrends);
    yield takeLatest(ActionTypes.DATA_RESULTS_RECEIVED, fetchTrends);
    yield takeLatest(ActionTypes.UI_DASHBOARD_CURRENT_KEYWORD_ID_SET, fetchTrends);
    yield takeLatest(ActionTypes.UI_DEFAULTS_SHARED_CURRENT_KEYWORD_GRAPH_SET, fetchTrends);
}

function* watchListDataRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_REQUESTED, fetchListData);
}

function* watchListDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_DELETE_REQUESTED, deleteList);
}

function* watchListRenameRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_RENAME_REQUESTED, renameList);
}

function* watchSelectedKwExportRequests() {
    yield takeLatest(ActionTypes.DATA_SELECTED_KW_EXPORT_REQUESTED, exportSelectedKeywordsData);
}

function* watchSerpResultsReceived() {
    yield takeLatest(ActionTypes.DATA_SERP_RESULTS_RECEIVED, updateKeywordRank);
    yield takeLatest(ActionTypes.DATA_SERP_RESULTS_RECEIVED, updateContentTypeFromSerps);
}

function* watchRefreshRequestsReceived() {
    yield takeLatest(ActionTypes.DATA_RESULTS_KEYWORD_RANK_RECEIVED, updateContentTypeFromRefresh);
}

function* watchResultsReceived() {
    yield takeLatest(ActionTypes.DATA_RESULTS_RECEIVED, updateDefaultLocation);
}

function* watchAddToListRequests() {
    yield takeLatest(ActionTypes.DATA_SELECTED_KW_ADD_TO_LIST_REQUESTED, addOrCreateList);
}

function* watchShowListRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_KEYWORDS_REQUESTED, loadDashboardWithListData);
}

function* watchHistoryRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_REQUESTED, fetchHistoryData);
}

function* watchKdHistoryRequests() {
    yield takeLatest(ActionTypes.DATA_KD_HISTORY_REQUESTED, fetchKdHistoryData);
}

function* watchImportRequests() {
    yield takeLatest(ActionTypes.DATA_IMPORT_REQUESTED, loadDashboardWithImportedData);
}

function* watchImportCurrentKeywordsRequests() {
    yield takeLatest(ActionTypes.DATA_IMPORT_CURRENT_KEYWORDS_REQUESTED, importCurrentKeywords);
}

function* watchListDeleteKeywordsFromCurrentListRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_REQUESTED, deleteKeywordsFromCurrentList);
}

function* watchListDeleteKeywordFromListRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_DELETE_KEYWORD_REQUESTED, deleteKeywordFromList);
}

function* watchHistoryDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_DELETE_REQUESTED, deleteHistoryData);
}

function* watchKdHistoryDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_KD_HISTORY_DELETE_REQUESTED, deleteKdHistoryData);
}

function* watchUrlDataRequests() {
    yield takeLatest(ActionTypes.DATA_URL_DATA_REQUESTED, fetchUrlData);
}

function* watchSearchIntentRequests() {
    yield takeLatest(ActionTypes.DATA_SEARCH_INTENT_REQUESTED, fetchSearchIntent);
}

function* watchContentTypesRequests() {
    yield takeLatest(ActionTypes.DATA_CONTENT_TYPES_REQUESTED, fetchContentType);
}

function* watchBulkRefreshRequests() {
    yield takeLatest(ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_PROCEED, bulkFetchSerpResults, {
        disableCache: false,
        retrying: false,
    });
}

function newAppVersionCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.APP_VERSION_CHECK_INTERVAL);

        return () => {
            clearInterval(intervalId);
        };
    });
}

function* watchNewAppVersionByInterval() {
    const channel = yield call(newAppVersionCheckIntervalChannel);
    yield takeEvery(channel, checkNewAppVersion);
}

export function* fetchAfterLoginData() {
    yield spawn(fetchListData);
    yield spawn(fetchAnnouncements);
    yield spawn(checkNewAppVersion);
    yield spawn(fetchCurrencyRates);
    yield spawn(checkUnleashSession);
}

export function* watchDataRequests() {
    yield spawn(watchAddToListRequests);
    yield spawn(watchKeywordsByDomainResultsRequests);
    yield spawn(watchCompetitorsByDomainResultsRequests);
    yield spawn(watchGoogleAutocompleteResultsRequests);
    yield spawn(watchGoogleQuestionsResultsRequests);
    yield spawn(watchHistoryRequests);
    yield spawn(watchKdHistoryRequests);
    yield spawn(watchImportCurrentKeywordsRequests);
    yield spawn(watchImportRequests);
    yield spawn(watchListDataRequests);
    yield spawn(watchListDeleteKeywordsFromCurrentListRequests);
    yield spawn(watchListDeleteRequests);
    yield spawn(watchListRenameRequests);
    yield spawn(watchLocationsRequests);
    yield spawn(watchResultsReceived);
    yield spawn(watchResultsRequests);
    yield spawn(watchSerpResultsMoreRequests);
    yield spawn(watchSerpResultsReceived);
    yield spawn(watchSerpResultsRequests);
    yield spawn(watchShowListRequests);
    yield spawn(watchTrendsRequests);
    yield spawn(watchUncachedSerpResultsRequests);
    yield spawn(watchSelectedKwExportRequests);
    yield spawn(watchHistoryDeleteRequests);
    yield spawn(watchKdHistoryDeleteRequests);
    yield spawn(watchBulkRefreshRequests);
    yield spawn(watchListDeleteKeywordFromListRequests);
    yield spawn(watchNewAppVersionByInterval);
    yield spawn(watchUrlDataRequests);
    yield spawn(watchRefreshRequestsReceived);
    yield spawn(watchSearchIntentRequests);
    yield spawn(watchContentTypesRequests);
}
