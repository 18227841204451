import React from "react"
import { useDispatch } from "react-redux"
import { showKeywordGapMessage } from "../../actions/uiActions"
import { gtmTrack } from "../../actions/analyticsActions"
import { analyticsActions, analyticsEvents } from "../../constants/analytics"

export const KeywordGapButton = () => {
    const dispatch = useDispatch()
    return (
        <button
            onClick={() => {
                dispatch(showKeywordGapMessage())
                dispatch(
                    gtmTrack({
                        action: analyticsActions.CLICK,
                        event: analyticsEvents.KEYWORD_GAP_MESSAGE,
                    }),
                );
            }}
            className="uk-position-relative"
            type="button"
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            Keyword Gap
            <strong
                className="mg-label is-white is-xsmall font-10 mg-margin-l-5"
            >
                NEW
            </strong>
        </button>
    )
}