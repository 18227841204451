import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    accessDenied: {
        visibility: false,
    },
    addToList: {
        addingKeywordIds: [],
        visibility: false,
    },
    deleteConfirmation: {
        resourceName: '',
        resourceType: '',
        visibility: false,
    },
    failure: {
        visibility: false,
        details: null,
        header: null,
    },
    gaugeInfo: {
        visibility: false,
    },
    refreshConfirm: {
        visibility: false,
    },
    loggedOut: {
        visibility: false,
    },
    noConnection: {
        visibility: false,
    },
    needToSelectKeyword: {
        visibility: false,
    },
    needToSignIn: {
        visibility: false,
    },
    pricing: {
        visibility: false,
    },
    shortcuts: {
        visibility: false,
    },
    topLevel: {
        announcement: {
            visibility: true,
        },
    },
    urlTypeInfo: {
        visibility: false,
    },
    upgradeForMoreResults: {
        visibility: false,
    },
    currencies: {
        visibility: false,
    },
    trackInSerpwatcher: {
        listId: null,
        visibility: false,
    },
    keywordGap: {
        visibility: false,
    }
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: true,
                    },
                    resourceName: {
                        $set: action.payload.resourceName,
                    },
                    resourceType: {
                        $set: action.payload.resourceType,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM:
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: false,
                    },
                    resourceName: {
                        $set: initialState.deleteConfirmation.resourceName,
                    },
                    resourceType: {
                        $set: initialState.deleteConfirmation.resourceType,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_SHOW: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: true,
                    },
                    details: {
                        $set: action.payload.details,
                    },
                    header: {
                        $set: action.payload.header,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_CLOSE: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: false,
                    },
                    details: {
                        $set: null,
                    },
                    header: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ADD_TO_LIST_SHOW: {
            return update(state, {
                addToList: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.DATA_SELECTED_KW_ADD_TO_LIST_REQUESTED:
        case ActionTypes.UI_MESSAGES_ADD_TO_LIST_CLOSE: {
            return update(state, {
                addToList: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ADD_TO_LIST_ADDING_KW_IDS_SET: {
            return update(state, {
                addToList: {
                    addingKeywordIds: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_GAUGE_INFO_SHOW: {
            return update(state, {
                gaugeInfo: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_GAUGE_INFO_CLOSE: {
            return update(state, {
                gaugeInfo: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_SHOW: {
            return update(state, {
                refreshConfirm: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_PROCEED:
        case ActionTypes.UI_MESSAGES_REFRESH_CONFIRM_CLOSE: {
            return update(state, {
                refreshConfirm: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW: {
            return update(state, {
                needToSelectKeyword: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE: {
            return update(state, {
                needToSelectKeyword: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_SHOW: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_CLOSE: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: {
            return update(state, {
                topLevel: {
                    announcement: {
                        visibility: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_SHOW: {
            return update(state, {
                upgradeForMoreResults: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UPGRADE_FOR_MORE_RESULTS_CLOSE: {
            return update(state, {
                upgradeForMoreResults: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW: {
            return update(state, {
                shortcuts: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE: {
            return update(state, {
                shortcuts: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_CURRENCIES_SHOW: {
            return update(state, {
                currencies: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_CURRENCIES_CLOSE: {
            return update(state, {
                currencies: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_KEYWORD_GAP_SHOW: {
            return update(state, {
                keywordGap: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_KEYWORD_GAP_CLOSE: {
            return update(state, {
                keywordGap: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_TRACK_IN_SW_SHOW: {
            return update(state, {
                trackInSerpwatcher: {
                    listId: { $set: action.payload },
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_SERPWATCHER_TRACKINGS_ERROR:
        case ActionTypes.UI_MESSAGES_TRACK_IN_SW_CLOSE: {
            return update(state, {
                trackInSerpwatcher: { $set: initialState.trackInSerpwatcher },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: { $set: false },
                },
                addToList: {
                    visibility: { $set: false },
                },
                deleteConfirmation: {
                    resourceName: { $set: '' },
                    resourceType: { $set: '' },
                    visibility: { $set: false },
                },
                failure: {
                    visibility: { $set: false },
                    details: { $set: null },
                    header: { $set: null },
                },
                loggedOut: {
                    visibility: { $set: false },
                },
                gaugeInfo: {
                    visibility: { $set: false },
                },
                refreshConfirm: {
                    visibility: { $set: false },
                },
                noConnection: {
                    visibility: { $set: false },
                },
                needToSelectKeyword: {
                    visibility: { $set: false },
                },
                pricing: {
                    visibility: { $set: false },
                },
                urlTypeInfo: {
                    visibility: { $set: false },
                },
                upgradeForMoreResults: {
                    visibility: { $set: false },
                },
                shortcuts: {
                    visibility: { $set: false },
                },
                currencies: {
                    visibility: { $set: false },
                },
                trackInSerpwatcher: {
                    visibility: { $set: false },
                },
                keywordGap: {
                    visibility: { $set: false },
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default messagesReducer;
